<template>
  <div>
    <el-card>
      <el-row :gutter="22">
        <el-col :span="1.5">
          <div>用户Id：</div>
        </el-col>
        <el-col :span="3">
          <el-input v-model="detailed_data.user_id"></el-input>
        </el-col>
        <el-col :span="1.5">
          <div>渠道：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="detailed_data.channel_type" placeholder="请选择">
            <el-option
                v-for="item in channel"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="1.5">
          <div>类型条件：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="detailed_data.vip_type" placeholder="请选择">
            <el-option
                v-for="item in type_condition"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>


        <el-button style="margin-left: 20px" type="primary" @click="query">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table border style="width: 100%" :data="list_data">
        <el-table-column prop="id" label="用户编号" width="80"/>
        <el-table-column prop="user_id" label="用户ID" width="100"/>
        <el-table-column label="类型">
          <template slot-scope="scope">
            <div>
              {{ scope.row.type == 'vip' ? "会员" : scope.row.type == 'tong' ? '同城卡' : '曝光卡' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="渠道">
          <template slot-scope="scope">
            <div>
              {{ scope.row.channel == "1" ? "购买获得" : "黑钻领取" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="时间">
          <template slot-scope="scope">
            <div>
              {{myDayJs(scope.row.created_at * 1000).format("YYYY-MM-DD HH:mm:ss") }}  --
              {{myDayJs(scope.row.updated_at * 1000).format("YYYY-MM-DD HH:mm:ss") }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="detailed_data.pageNum"
          :page-size="detailed_data.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total_count">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { treasureCard} from "../https";
import dayjs from 'dayjs'

export default {
  name: "nGetTreasureCard",
  data() {
    return {
      channel:[{
        value: '1',
        label: '购买获得'
      }, {
        value: '2',
        label: '黑钻领取'
      }],
      statistics_type:'',
      member_type:'',
      type_condition: [{
        value: 'vip',
        label: '会员'
      }, {
        value: 'tong',
        label: '同城卡'
      }, {
        value: 'bao',
        label: '曝光卡'
      }],
      detailed_data: {
        user_id:"",
        channel_type: '',//渠道
        vip_type: '',//会员类型
        pageNum: 1,
        limit: 10,//每页数量
      },
      list_data: [],//所有数据
      total_count: 0,
      total_page: 0,
      myDayJs: dayjs,

    }
  },
  methods: {
    //收益统计Api
    memberListApi(data) {
      return new Promise(() => {
        treasureCard(data).then(res => {
          this.list_data = res.data.data.list;
          this.total_count = res.data.data.totalCount;
          this.total_page = res.data.data.totalPage;
        })
      })
    },
    //detailed_data.pageNum 改变时会触发
    handleSizeChange(val) {
      this.detailed_data.limit = val;
      this.memberListApi(this.detailed_data)
    },
    //detailed_data.limit 改变时会触发
    handleCurrentChange(val) {
      this.detailed_data.pageNum = val
      this.memberListApi(this.detailed_data)
    },
    //重置
    resetButtonValue(){
      this.detailed_data.user_id = ''
      this.detailed_data.channel_type = ''
      this.detailed_data.vip_type = ''
      this.detailed_data.pageNum = 1
      this.detailed_data.limit = 10
      this.memberListApi({pageNum:1,limit:10})
    },
    //查询
    query() {
      let data = {
        type: this.detailed_data.vip_type.length != 0 ? this.detailed_data.vip_type : null,
        channel: this.detailed_data.channel_type.length != 0 ? this.detailed_data.channel_type : null,
        user_id: this.detailed_data.user_id.length != 0 ? this.detailed_data.user_id : null,
        pageNum: this.detailed_data.pageNum,
        limit: this.detailed_data.limit,//每页数量
      }
      console.log(data)
      this.memberListApi(data)
    },
  },
  beforeMount() {
    this.query()
  }
}
</script>

<style scoped>

</style>