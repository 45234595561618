<template>
  <div>
    <el-card>
      <el-row :gutter="24">
        <el-col :span="1.5">
          <div>时间范围：</div>
        </el-col>
        <el-col :span="7">
          <el-date-picker
              v-model="value1"
              type="daterange"
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" @click="showUserRegister()">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-button type="primary" @click="refresh">刷新</el-button>
      <br><br>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="date" label="时间" width="200"/>
        <el-table-column prop="number" label="数量" width="200"/>
        <el-table-column prop="range" label="时间范围"/>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import {
  formatDateTime
} from "@/libs/tools";
import {
  showUserRegister
} from "@/https";

export default {
  name: "UserRegister",
  data() {
    return {
      user: {
        dayTime: "",
        dayNum: "",
        weekTime: "",
        weekNum: "",
        monthTime: "",
        monthNum: ""
      },
      tableData: [],
      value1: ''
    }
  },
  methods: {
    refresh() {
      this.tableData = []
      this.showUserRegister()
    },
    showUserRegister() {
      this.tableData = []
      return new Promise(() => {
        showUserRegister({
          createStart: this.value1[0],
          createEnd: this.value1[1]
        }).then(res => {
          this.tableData.push({
            date: "今天注册",
            number: res.data.data.day.data,
            range: formatDateTime(res.data.data.day.time * 1000) + "----" + formatDateTime(Date.now())
          }, {
            date: "今日活跃",
            number: res.data.data.day.active,
            range: formatDateTime(res.data.data.day.time * 1000) + "----" + formatDateTime(Date.now())
          }, {
            date: "今天通过审核",
            number: res.data.data.day.perfectPass,
            range: formatDateTime(res.data.data.day.time * 1000) + "----" + formatDateTime(Date.now())
          }, {
            date: "今日提交审核",
            number: res.data.data.day.perfect,
            range: formatDateTime(res.data.data.day.time * 1000) + "----" + formatDateTime(Date.now())
          }, {
            date: "七天内注册",
            number: res.data.data.week.data,
            range: formatDateTime(res.data.data.week.time * 1000) + "----" + formatDateTime(Date.now())
          }, {
            date: "七天内活跃",
            number: res.data.data.week.active,
            range: formatDateTime(res.data.data.week.time * 1000) + "----" + formatDateTime(Date.now())
          }, {
            date: "本月注册",
            number: res.data.data.month.data,
            range: formatDateTime(res.data.data.month.time * 1000) + "----" + formatDateTime(Date.now())
          }, {
            date: "本月活跃",
            number: res.data.data.month.active,
            range: formatDateTime(res.data.data.month.time * 1000) + "----" + formatDateTime(Date.now())
          }, {
            date: "总注册",
            number: res.data.data.register.data,
            range: formatDateTime(res.data.data.register.time * 1000) + "----" + formatDateTime(Date.now())
          }, {
            date: "信息完善用户",
            number: res.data.data.register.perfect,
            range: formatDateTime(res.data.data.register.time * 1000) + "----" + formatDateTime(Date.now())
          }, {
            date: "时间段内注册",
            number: res.data.data.time.data,
            range: res.data.data.time.data === null ? null : formatDateTime(res.data.data.time.createStart * 1000) + "----" + formatDateTime(res.data.data.time.createEnd * 1000)
          })
        })
      })
    },
    resetButtonValue() {
      this.value1 = ''
      this.showUserRegister()
    },
  },
  mounted: function () {
    this.refresh();
  }

}
</script>

<style scoped>

</style>