<template>
  <div class="login-container">
    <el-form ref="form" :rules="rules" :model="form" label-width="80px" class="login-form">
      <h2 class="login-title">后台管理系统</h2>
      <el-form-item label="用户名" prop="username">
        <el-input v-model="form.username"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="form.password" type="password" @change="submitForm('form')"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('form')"  >登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  login
} from "@/https";
import {
  setToken,
  setUserId,
  setUserName
} from "@/https";
import router from "@/router";

export default {
  name: "Login.vue",
  data() {
    return {
      user: {
        name: '',
        psw: ''
      },
      form: {
        username: "",
        password: ""
      },
      rules: {
        username: [
          {required: true, message: "用户名不能为空", trigger: 'blur'},
          {min: 3, max: 10, message: "用户名3-5位", trigger: 'blur'}
        ],
        password: [
          {required: true, message: "密码不能为空", trigger: 'blur'},
          {min: 3, max: 10, message: "密码3-5位", trigger: 'blur'}
        ]
      }
    };
  },
  methods: {
    functions(){
      console.log('123')
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        // console.log(valid) 验证通过为true，有一个不通过就是false
        if (valid) {
          // 通过的逻辑
          const n = this.form.username;
          const p = this.form.password;
          login({n, p}).then(res => {
            const data = res.data
            if (data.code === 200) {
              setToken(res.data.token)
              localStorage.setItem('findBoy',res.data.token)
              setUserId(res.data.data.id)
              setUserName(res.data.data.username)
              router.push("/privatePhone")
            }else {
              this.open(data.msg)
            }
          })
        } else {
          console.log('验证失败');
          return false;
        }
      });
    },
    open(msg) {
      this.$message.error(msg)
    },
    // 判断是否有token
    judgeToken(){
      const token = localStorage.getItem('findBoy')
      console.log(token,'token');
      if (token === null || token === undefined){
        return;

      }
      setToken(token)
      this.$router.push("/privatePhone")
    }


  },
  beforeMount() {
    // this.judgeToken()

  },
  beforeCreate() {
    localStorage.removeItem('findBoy');

  }
}
</script>

<style scoped>
.login-form {
  width: 350px;
  margin: 160px auto; /* 上下间距160px，左右自动居中*/
  background-color: rgb(255, 255, 255, 0.8);
  padding: 30px;
  border-radius: 20px; /* 圆角 */
}

/* 背景 */
.login-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #42b983;
}

/* 标题 */
.login-title {
  color: #303133;
  text-align: center;
}
</style>
