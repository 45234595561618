<template>
  <div>
    <el-card>
      <el-table border  :data="table_data"  style="width: 100%" >
        <el-table-column prop="start_time" label="活动开始时间" align="center" width="300">
        </el-table-column>
        <el-table-column  prop="apply_num" label="人数" align="center" />

      </el-table>
    </el-card>
    <!--  上传活动图片弹出框  -->
    <el-dialog title="修改价格" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="普通用户/普通会员" :label-width="formLabelWidth">
          <el-input v-model="form.user_price" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="黑钻会员" :label-width="formLabelWidth">
          <el-input v-model="form.member_price" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
// import {weekendRotationUpload} from "../https";
// import dayjs from "dayjs";

// import dayjs from "dayjs";

import {activityDetails, getPrivice} from "../https";

export default {
  name: "weekendDetails",
  data(){
    return {
      table_data:[],
      activity_type:[{
        text: '周末夜话',
        value: '周末夜话'
      }, {
        text: '周末夜话',
        value: '周末夜话'
      }],//
      form:{
        user_price:'',
        member_price:''
      },
      data_list:{},//接口数据
      dialogFormVisible:false,//编辑弹出框
      formLabelWidth:'120rpx',
    }
  },
  methods:{
    //轮播图列表
    async activityDetailsApi(data){
      await activityDetails(data).then(res=>{
        console.log(res.data.data.data)
        this.table_data = res.data.data.data
      })
    },
    //修改价格 || 轮播图开关
    async getPriviceApi(data){
      await getPrivice(data).then(res=>{
        if(res.data.code == '200') {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.swiperListApi({});
        }

      })
    },
    // 取消
    cancel(){
      this.form.member_price = ''
      this.form.user_price = ''
      this.dialogFormVisible = false
      this.swiperListApi({});
    },
    // 编辑
    edit(e){
      console.log(e);
      this.dialogFormVisible = true;
      this.form = e
      console.log(this.form )
    },
    // 确定
    determine(){
      this.dialogFormVisible = false;
      this.getPriviceApi(this.form)
      this.form.member_price = ''
      this.form.user_price = ''
      // this.form =
    },
    change(e){
      let data = {
        act_id:'-1',
        act_type:'yunying',
        slideshow: e
      }
      console.log(data);
      // return;
      this.getPriviceApi(data)
    }
  },
  beforeMount() {
    this.activityDetailsApi({});
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.dialog{
  display: flex;
  align-items: center;
  justify-items: center;
}
.el-form-item label:after {
  content: "";
  display: inline-block;
  width: 100%;
}
.el-form-item__label {
  text-align: justify;
  height: 50px;
}

</style>
