<template>
  <div>
    <el-card>
      <el-row :gutter="24">
        <el-col :span="1.5">
          <div>用户Id：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="like.fromId"></el-input>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>被喜欢用户Id：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="like.forId"></el-input>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>状态：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="like.status" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" @click="like.pageNum=1,showLike()">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="id" label="喜欢编号" width="100"/>
        <el-table-column prop="fromId" label="用户Id" width="150"/>
        <el-table-column prop="forId" label="被喜欢用户Id" width="150"/>
        <el-table-column prop="status" label="喜欢状态" width="100"/>
        <el-table-column prop="createAt" label="创建时间"/>
      </el-table><br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="like.pageNum"
          :page-sizes="[10, 50, 100, 500]"
          :page-size="like.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import {showLike} from "@/https";
import {formatDateTime} from "@/libs/tools";

export default {
  name: "Like.vue",
  data() {
    return {
      like: {
        fromId: "",
        forId: "",
        status: "",
        pageNum: 1,
        limit: 10
      },
      options: [{
        value: '0',
        label: '不喜欢'
      }, {
        value: '1',
        label: '喜欢'
      }],
      tableData: [],
      total: 0
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.like.limit = val
      this.showLike()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.like.pageNum = val
      this.showLike()
    },
    resetButtonValue() {
      this.like.fromId = ''
      this.like.forId = ''
      this.like.status = ''
      this.like.pageNum = 1
      this.like.limit = 10
      this.showLike()
    },
    showLike() {
      this.tableData = []
      this.spinShow = true
      return new Promise(() => {
        showLike({
          fromId: this.like.fromId,
          forId: this.like.forId,
          status: this.like.status,
          pageNum: this.like.pageNum,
          limit: this.like.limit
        }).then(res => {

          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.list.length; i++) {
            this.tableData.push({
              id: data.list[i].id,
              fromId: data.list[i].fromId,
              forId: data.list[i].forId,
              status: data.list[i].status === 0 ? '不喜欢' : '喜欢',
              createAt: formatDateTime(data.list[i].createAt * 1000)
            })
          }
        })
      })
    }
  },
  mounted: function () {
    this.showLike();
  }
}
</script>

<style scoped>

</style>