<template>
  <div>
    <el-card>
      <el-row :gutter="24">
        <el-col :span="1.5" :offset="0.5">
          <div>用户Id：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="blackPost.userId"></el-input>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>封禁状态：</div>
        </el-col>
        <el-col :span="2">
          <el-select v-model="blackPost.status" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" @click="blackPost.pageNum=1,showBlack()">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-button type="primary" @click="openDialog">添加封禁</el-button>
      <br><br>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="blackId" label="封禁编号" width="80"/>
        <el-table-column prop="userId" label="用户Id" width="80"/>
        <el-table-column prop="description" label="描述" width="250"/>
        <el-table-column prop="status" label="封禁状态" width="80">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="danger"
                @click="changeBlack(scope.$index, scope.row)"
                v-if="scope.row.status==='封禁'"
                v-text="scope.row.status">
            </el-button>
            <el-button
                size="mini"
                type="primary"
                @click="changeBlack(scope.$index, scope.row)"
                v-if="scope.row.status==='解封'"
                v-text="scope.row.status">
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="createAt" label="创建时间" width="160"/>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="blackPost.pageNum"
          :page-sizes="[10, 50, 100, 500]"
          :page-size="blackPost.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
    <el-dialog title="添加封禁" :visible.sync="dialogFormVisible" center width="800px" :modal-append-to-body="false">
      <el-form :model="form">
        <el-form-item label="用户Id" :label-width="formLabelWidth">
          <el-input v-model="form.userId" style="width: 150px"></el-input>
        </el-form-item>
        <el-form-item label="描述" :label-width="formLabelWidth">
          <el-input type="textarea"
                    placeholder="请输入内容"
                    v-model="form.description"
                    style="width: 300px"
                    autosize></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addBlack">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {formatDateTime} from "@/libs/tools";
import {addBlack, changeBlack, showBlack} from "@/https";

export default {
  name: "BlackPost",
  data() {
    return {
      blackPost: {
        userId: "",
        status: "",
        pageNum: 1,
        limit: 10
      },
      options: [{
        value: '0',
        label: '封禁'
      }, {
        value: '1',
        label: '解封'
      }],
      form: {
        userId: '',
        description: ''
      },
      dialogTableVisible: false,
      dialogFormVisible: false,
      formLabelWidth: '200px',
      tableData: [],
      total: 0
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.blackPost.limit = val
      this.showBlack()
    },
           handleCurrentChange(val) {
                  console.log(`当前页: ${val}`)
      this.blackPost.pageNum = val
      this.showBlack()
    },
    resetButtonValue() {
      this.blackPost.userId = ''
      this.blackPost.status = ''
      this.blackPost.pageNum = 1
      this.blackPost.limit = 10
      this.showBlack()
    },
    openDialog() {
      this.form.userId = ''
      this.form.description = ''
      this.dialogFormVisible = true
    },
    showBlack() {
      this.tableData = []
      this.spinShow = true
      return new Promise(() => {
        showBlack({
          userId: this.blackPost.userId,
          status: this.blackPost.status,
          pageNum: this.blackPost.pageNum,
          limit: this.blackPost.limit
        }).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.list.length; i++) {
            this.tableData.push({
              blackId: data.list[i].blackId,
              userId: data.list[i].userId,
              description: data.list[i].description,
              status: data.list[i].status === 0 ? '封禁' : '解封',
              createAt: formatDateTime(data.list[i].createAt * 1000),
            })
          }
        })
      })
    },
    changeBlack(index, row) {
      return new Promise(() => {
        changeBlack({
          blackId: row.blackId
        }).then(res => {
          if (res.data.code === 200) {
            row.status = (row.status === '封禁' ? '解封' : '封禁')
          }else {
            this.open(res.data.msg)
          }
        })
      })
    },
    addBlack() {
      return new Promise(() => {
        addBlack({
          userId: this.form.userId,
          description: this.form.description
        }).then(res => {
          if (res.data.code === 200) {
            this.dialogFormVisible = false
            this.showBlack()
          } else {
            this.open(res.data.msg)
          }
        })
      })
    },
    open(msg) {
      this.$message.error(msg)
    }
  },
  mounted: function () {
    this.showBlack();
  }
}
</script>

<style scoped>

</style>