<template>
  <div>
    <el-card>
      <el-row :gutter="24">
        <el-col :span="1.5">
          <div>订单编号：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="order.id"></el-input>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>用户Id：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="order.userId"></el-input>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>商家订单号：</div>
        </el-col>
        <el-col :span="4">
          <el-input v-model="order.orderId"></el-input>
        </el-col>
        <el-col :span="1.5">
          <div>商品编号：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="order.goodsId"></el-input>
        </el-col>
        <br><br><br>
        <el-col :span="1.5">
          <div>支付时间：</div>
        </el-col>
        <el-col :span="7">
          <el-date-picker
              v-model="value1"
              type="daterange"
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" @click="order.pageNum=1,showOrder()">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="id" label="订单编号" width="80"/>
        <el-table-column prop="userId" label="用户Id" width="80"/>
        <el-table-column prop="orderId" label="商家订单号"/>
        <el-table-column prop="description" label="描述" width="120"/>
        <el-table-column prop="createAt" label="创建时间" width="160"/>
        <el-table-column prop="goodsId" label="商品编号" width="80"/>
        <el-table-column prop="rType" label="商品类型" width="100"/>
        <el-table-column prop="payFee" label="支付金额" width="80"/>
        <el-table-column prop="payment" label="支付方式" width="100"/>
        <el-table-column prop="status" label="订单状态" width="80"/>
        <el-table-column prop="payAt" label="支付时间" width="160"/>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                type="primary">编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="order.pageNum"
          :page-sizes="[10, 50, 100, 500]"
          :page-size="order.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
    <el-dialog title="修改订单信息" :visible.sync="dialogFormVisible" center style="width: 1500px" :modal-append-to-body = "false">
      <el-form :model="orderModel">
        <el-row :span="15">
          <el-col :span="5">
            <el-form-item label="订单编号" :label-width="formLabelWidth">
              <el-input v-model="orderModel.id" style="width: 100px" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="1">
            <el-form-item label="用户Id" :label-width="formLabelWidth">
              <el-input v-model="orderModel.userId" style="width: 100px" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3" :offset="1">
            <el-form-item label="商家订单号" :label-width="formLabelWidth">
              <el-input v-model="orderModel.orderId" style="width: 200px" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="15">
          <el-col :span="5">
            <el-form-item label="商品Id" :label-width="formLabelWidth">
              <el-input v-model="orderModel.goodsId" style="width: 100px" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="1">
            <el-form-item label="商品描述" :label-width="formLabelWidth">
              <el-input v-model="orderModel.description" style="width: 100px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="创建时间" :label-width="formLabelWidth">
              <el-input v-model="orderModel.createAt" style="width: 200px" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="15">
          <el-col :span="5">
            <el-form-item label="会员类型" :label-width="formLabelWidth">
              <el-select v-model="orderModel.rType" placeholder="会员类型" style="width: 100px">
                <el-option label="月会员" value="1"></el-option>
                <el-option label="年会员" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="支付金额" :label-width="formLabelWidth">
              <el-input v-model="orderModel.payFee" style="width: 80px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="支付状态" :label-width="formLabelWidth">
              <el-select v-model="orderModel.status" placeholder="支付状态" style="width: 120px">
                <el-option label="未支付" value="0"></el-option>
                <el-option label="完成" value="1"></el-option>
                <el-option label="关闭" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="支付时间" :label-width="formLabelWidth">
              <el-input v-model="orderModel.payAt" style="width: 200px" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeOrder">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {changeOrder, showOrder, showOrderById} from "@/https";
import {formatDateTime} from "@/libs/tools";

export default {
  name: "Order.vue",
  data() {
    return {
      order: {
        id: "",
        userId: "",
        orderId: "",
        goodsId: "",
        payment: "",
        pageNum: 1,
        limit: 10
      },
      orderModel: {
        id: "",
        userId: "",
        orderId: "",
        description: "",
        createAt: "",
        goodsId: "",
        rType: "",
        payment: "",
        payFee: "",
        status: "",
        payAt: "",
      },
      dialogTableVisible: false,
      dialogFormVisible: false,
      formLabelWidth: '120px',
      tableData: [],
      total: 0,
      value1: ''
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.order.limit = val
      this.showOrder()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.order.pageNum = val
      this.showOrder()
    },
    resetButtonValue() {
      this.order.id = ''
      this.order.userId = ''
      this.order.orderId = ''
      this.order.goodsId = ''
      this.order.pageNum = 1
      this.order.limit = 10
      this.value1 = ''
      this.showOrder(1)
    },
    showOrder() {
      this.tableData = []
      this.spinShow = true
      return new Promise(() => {
        showOrder({
          id: this.order.id,
          userId: this.order.userId,
          orderId: this.order.orderId,
          goodsId: this.order.goodsId,
          payment: this.order.payment,
          pageNum: this.order.pageNum,
          payAtStart: this.value1[0],
          payAtEnd: this.value1[1],
          limit: this.order.limit
        }).then(res => {

          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.list.length; i++) {
            this.tableData.push({
              id: data.list[i].id,
              userId: data.list[i].userId,
              orderId: data.list[i].orderId,
              description: data.list[i].description,
              createAt: formatDateTime(data.list[i].createAt * 1000),
              goodsId: data.list[i].goodsId,
              rType: data.list[i].rtype === 2 ? '年会员' : '月会员',
              payment: data.list[i].payment === 0 ? '微信' : data.list[i].payment === 1 ? '支付宝' : 'ios',
              payFee: data.list[i].payFee,
              status: data.list[i].status === 0 ? '未支付' : data.list[i].status === 1 ? '完成' : '关闭',
              payAt: data.list[i].payAt === null ? '' : formatDateTime(data.list[i].payAt * 1000),
            })
          }
        })
      })
    },
    handleEdit(index, row) {
      return new Promise(() => {
        showOrderById({
          id: row.id
        }).then(res => {
          let data = res.data.data
          this.dialogFormVisible = true
          this.orderModel.id = data.id
          this.orderModel.userId = data.userId
          this.orderModel.orderId = data.orderId
          this.orderModel.description = data.description
          this.orderModel.createAt = formatDateTime(data.createAt * 1000)
          this.orderModel.goodsId = data.goodsId
          this.orderModel.rType = data.rtype === 1 ? '月会员' : '年会员'
          this.orderModel.payFee = data.payFee
          this.orderModel.status = data.status === 0 ? '未支付' : data.status === 1 ? '完成' : '关闭'
          this.orderModel.payAt = formatDateTime(data.payAt * 1000)
        })
      })
    },
    changeOrder() {
      return new Promise(() => {
        changeOrder({
          id: this.orderModel.id,
          // userId: this.orderModel.userId,
          // orderId: this.orderModel.orderId,
          description: this.orderModel.description,
          // createAt: this.orderModel.createAt,
          // goodsId: this.orderModel.goodsId,
          rtype: this.orderModel.rType === '月会员' ? 1 : this.orderModel.rType === '年会员' ? 2 : this.orderModel.rType,
          payFee: this.orderModel.payFee,
          status: this.orderModel.status === '未支付' ? 0 : this.orderModel.status === '完成' ? 1 : this.orderModel.status === '关闭' ? 2 : this.orderModel.status,
          // payAt: this.orderModel.payAt
        }).then(res => {
          if (res.data.code === 200) {
            this.dialogFormVisible = false
            this.showOrder()
          }else {
            this.open(res.data.msg)
          }
        })
      })
    },
    open(msg) {
      this.$message.error(msg)
    }
  },
  mounted: function () {
    this.showOrder();
  }
}
</script>

<style scoped>

</style>