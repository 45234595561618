<template>
  <div>
    <el-card>
      <el-button @click="dialogFormVisible_add = true">添加时间</el-button>
      <el-button @click="dialogFormVisible_poster = true">海报详情</el-button>
      <el-button @click="dialogFormVisible_poster_add = true">上传海报</el-button>
    </el-card>
    <el-card>
      <el-table border  :data="table_data"  style="width: 100%" >
        <el-table-column label="活动类型" align="center"  :filters="activity_type">
          <template slot-scope="scope">
            <span> {{ scope.row.act_type == 'yunying' ? '周末夜话' : '无' }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="start_time" label="活动开始时间" :filters="activity_type" align="center" width="300">
        </el-table-column>
        <el-table-column  prop="apply_num" label="人数" align="center" />
        <el-table-column  label="价格" align="center" >
          <el-table-column  label="普通用户/vip" align="center" prop="user_price" />
          <el-table-column  label="黑钻会员" align="center" prop="member_price" />
        </el-table-column>
        <el-table-column  label="操作" align="center"  >
          <el-table-column label="轮播图开关" align="center">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.slideshow" :active-value="1" :inactive-value="0" @change="change">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="修改价格  " align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
          <el-table-column label="详情" align="center" >
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="see(scope.row.act_id)">查看</el-button>
              <el-button type="text" size="small" @click="add">添加</el-button>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </el-card>
    <!--  上传活动图片弹出框  -->
    <el-dialog title="修改价格" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="普通用户/普通会员" :label-width="formLabelWidth">
          <el-input v-model="form.user_price" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="黑钻会员" :label-width="formLabelWidth">
          <el-input v-model="form.member_price" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="详情" :visible.sync="dialogFormVisible_see" >
      <el-table :data="see_details">
        <el-table-column property="user_id" label="用户id" align="center"></el-table-column>
        <el-table-column property="created" label="用户支付时间" align="center" />
        <el-table-column  label="城市" width="200" align="center">
          <template slot-scope="scope">
            {{ scope.row.province }}  {{ scope.row.city }}
          </template>
        </el-table-column>
        <el-table-column property="user_type" label="属性" align="center"></el-table-column>
        <el-table-column  label="匹配" width="200" align="center">
          <template slot-scope="scope">
            {{ scope.row.contacts }}
          </template>
        </el-table-column>
        <el-table-column label="用户1" align="center" >
          <template slot-scope="scope">
            {{ scope.row.messageOne.province }}  {{ scope.row.messageOne.city }} {{ scope.row.messageOne.user_type }}
          </template>
        </el-table-column>

        <el-table-column label="用户2" align="center" >
          <template slot-scope="scope">
            {{ scope.row.messageTwo.province }}  {{ scope.row.messageTwo.city }}{{ scope.row.messageTwo.user_type }}
          </template>
        </el-table-column>


      </el-table>
      <el-button type="text" @click="matchingApi">匹配</el-button>
    </el-dialog>
    <el-dialog title="添加报名时间" :visible.sync="dialogFormVisible_add">
      <el-form :model="add_from">
        <el-form-item label="普通用户/普通会员价格" :label-width="formLabelWidth">
          <el-input v-model="add_from.user_price" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="黑钻会员价格" :label-width="formLabelWidth">
          <el-input v-model="add_from.member_price" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="determine_add">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="海报详情" :visible.sync="dialogFormVisible_poster">
      <el-table :data="poster_list">
        <el-table-column  label="图片" align="center">
          <template slot-scope="scope">
            <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.act_img"
               >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="act_name"    label="名字" align="center">
        </el-table-column>
        <el-table-column prop="update_time"  label="上次推送时间" align="center"/>
        <el-table-column  label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="pushPosterApi(scope.row.id)">推送</el-button>
            <el-button type="text" size="small" @click="modifyPoster(scope.row.id)">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog title="上传海报" :visible.sync="dialogFormVisible_poster_add">
      <el-upload
          class="avatar-uploader"
          action="http://fbadmin.laobitang.com/v2/userInfo/uploadPicture"
          :show-file-list="false"
          :on-success="handleAvatarSuccess">
        <img v-if="poster.act_img" :src="poster.act_img" class="avatar">
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <el-form :model="poster">
        <el-form-item label="名字" :label-width="formLabelWidth">
          <el-input v-model="poster.act_name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="posterAdd">确 定</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
// import {weekendRotationUpload} from "../https";
// import dayjs from "dayjs";

// import dayjs from "dayjs";

import {
  activityDetails,
  getPrivice,
  swiperList,
  matching,
  addActivityTime,
  addPoster,
  postList,
  pushPoster
} from "../https";

export default {
  name: "weekendTalk",
  data(){
    return {
      table_data:[],
      activity_type:[{
        text: '周末夜话',
        value: '周末夜话'
      }],//
      activity_type_add:[],
      form:{
        user_price:'',
        member_price:''
      },
      add_from:{
        act_type:'yunying',
        max_num:20,
        user_price:'',
        member_price:'',
        slideshow:0
      },//添加报名时间
      data_list:{},//接口数据
      dialogFormVisible:false,//编辑弹出框
      formLabelWidth:'120rpx',
      dialogFormVisible_see:false,//查看弹出框
      dialogFormVisible_add:false,//添加弹出框
      dialogFormVisible_poster:false,//海报弹出框
      dialogFormVisible_poster_add:false,//上传海报
      see_details:[],//查看数据
      act_id:'',
      poster:{
        act_name :'',//名字
        act_img:'',//上传的图片
        id:'',//修改图片的id
      },
      poster_list:[],//海报详情
      value:0,
      dialogVisible: false,
      disabled: false
    }
  },
  methods:{
    handleAvatarSuccess(res, file) {
      console.log()
      if (file.response.code == 200){
        this.poster.act_img = file.response.key
        console.log(file.response.key)
      }
    },
    //修改
    modifyPoster(e){
      this.poster.id = e
      this.dialogFormVisible_poster = false
      this.dialogFormVisible_poster_add = true
    },
     async posterAdd(){
       //去空
       for(let b in this.poster){
         if(!this.poster[b]){
           this.poster[b] = undefined
         }
       }
       await addPoster(this.poster).then(res=>{
        if(res.data.code == '200') {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.postListApi();
          this.cancel();
        }
      })
    },
    async postListApi(){
      await postList().then(res=>{
        console.log(res.data)
        if (res.data.data.status == 200){

          this.poster_list = res.data.data.data

        }
        console.log(this.poster_list)
      })
    },
    //推送
    pushPosterApi(id){
      this.$confirm('此操作将推送, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
         this.pushPosterAp(id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消推送'
        });
      });

    },
    //推送
    async pushPosterAp(id){
      await pushPoster(id).then(res=>{
        console.log(res)
        if(res.data.code == '200' && res.data.data.status == 200 ) {
          this.$message({
            message: '推送成功',
            type: 'success'
          });
          this.postListApi()
        }
      });
    },
    //轮播图列表
    async swiperListApi(data){
      await swiperList(data).then(res=>{
        console.log(res.data.data.data, 'res',res)

        this.activity_type_add = data
        console.log(this.activity_type_add,'data')
        this.table_data = res.data.data.data
      })
    },
    // 操作筛选

    //修改价格 || 轮播图开关
    async getPriviceApi(data){
      await getPrivice(data).then(res=>{
        if(res.data.code == '200') {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.swiperListApi({});
        }

      })
    },
    //匹配
    async matchingApi(){
      await matching({
        act_id:this.act_id
      }).then(res=>{
        if (res.data.data.status == 200){
          this.activityDetailsApi({
            act_id:this.act_id
          })
          this.act_id = ''
          this.$message({
            message: '匹配成功',
            type: 'success'
          });
        }
        console.log(res.data.data.status)
      })
    },
    // 取消
    cancel(){
      this.form.member_price = ''
      this.form.user_price = ''
      this.dialogFormVisible = false
      this.dialogFormVisible_add = false
      this.poster.act_name = ''
      this.poster.act_img = ''
      this.poster.id = ''
      this.add_from.member_price = ''
      this.dialogFormVisible_poster = false
      this.dialogFormVisible_poster_add = false
      this.swiperListApi({});

    },
    add(){
      // 添加
      this.dialogFormVisible_add = true
    },
    //添加
    determine_add(){
      this.addActivityTimeApi(this.add_from)
    },
    //添加时间api
    async addActivityTimeApi(data){
      await  addActivityTime(data).then(res=>{
        if (res.data.code == '200' && res.data.data.status == 200){
          this.$message({
            message: '添加成功',
            type: 'success'
          });
          this.swiperListApi({})
          this.dialogFormVisible_add = false
          this.add_from.user_price = ''
          this.add_from.member_price = ''
        }
        console.log(res)
      })
    },
    // 编辑
    edit(e){
      console.log(e);
      this.dialogFormVisible = true;
      this.form = e
      console.log(this.form )
    },
    // 确定
    determine(){
      this.dialogFormVisible = false;
      this.getPriviceApi(this.form)
      this.form.member_price = ''
      this.form.user_price = ''
      // this.form =
    },
    change(e){
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          act_id:'-1',
          act_type:'yunying',
          slideshow: e
        }
        this.getPriviceApi(data)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
        this.swiperListApi({})
      });

    },
    see(e){
      console.log(e)
      this.dialogFormVisible_see = true;
      this.act_id = e
      // act_id:
      this.activityDetailsApi({
        act_id:e
      })
    },
    //详情
    async activityDetailsApi(data){
      await activityDetails(data).then(res=>{
        if(res.data.code == '200') {
          console.log(res.data.data)
          this.see_details = res.data.data.data
          console.log(this.see_details)
        }

      })
    },
  },
  beforeMount() {
    this.swiperListApi({});
    this.postListApi();
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #c0ccda;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
