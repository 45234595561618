<template>
  <div style="overflow: auto;width: 100%;height: 100%">
      <div style="float: left;position: absolute;" :class='isCollapse == true ? "lf5" : "lf10"' class="a">
        <el-menu
            style="height:100vh;"
            default-active="/userRegister"
            class="el-menu-vertical-demo"
            background-color="#545c64"
            text-color="#fff"
            active-text-color="#ffd04b"
            :collapse-transition = "true"
            :collapse="isCollapse"
            router>
          <el-menu-item index="/privatePhone">
            <i class="el-icon-s-custom"></i>
            <span slot="title">私人订制手机号</span>
          </el-menu-item>
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>用户信息</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/userRegister" style="min-width: 0">用户注册量</el-menu-item>
              <el-menu-item index="/userAccount" style="min-width: 0">用户账户</el-menu-item>
              <el-menu-item index="/userInfo" style="min-width: 0">用户信息</el-menu-item>
<!--              <el-menu-item index="/userVip" style="min-width: 0">用户会员</el-menu-item>-->
              <el-menu-item index="/userVerify" style="min-width: 0">资料审核</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-document"></i>
              <span>周末夜话</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/weekendTalk" style="min-width: 0">轮播图列表</el-menu-item>
<!--              <el-menu-item index="/weekendDetails" style="min-width: 0">周末夜话详情</el-menu-item>-->
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-connection"></i>
              <span>广场</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/post" style="min-width: 0">帖子</el-menu-item>
              <el-menu-item index="/postVerify" style="min-width: 0">帖子审核</el-menu-item>
              <el-menu-item index="/comment" style="min-width: 0">评论</el-menu-item>
              <el-menu-item index="/watchInfo" style="min-width: 0">每日查看资料</el-menu-item>
              <el-menu-item index="/blackPost" style="min-width: 0">封禁名单</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <i class="el-icon-shopping-cart-full"></i>
              <span>订单</span>
            </template>
            <el-menu-item-group>
<!--              <el-menu-item index="/order" style="min-width: 0">订单查询</el-menu-item>-->
<!--              <el-menu-item index="/orderIncome" style="min-width: 0">订单收益</el-menu-item>-->
              <el-menu-item index="/perfectVip" style="min-width: 0">免费会员领取</el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="5">
            <template slot="title">
              <i class="el-icon-shopping-cart-full"></i>
              <span>新版会员</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/nOrderQuery" style="min-width: 0">订单查询</el-menu-item>
<!--              <el-menu-item index="/nIncomeStatistics" style="min-width: 0">收益统计</el-menu-item>-->
              <el-menu-item index="/nUserInfo" style="min-width: 0">用户信息</el-menu-item>

              <el-menu-item index="/nOrderRevenue" style="min-width: 0">订单收益</el-menu-item>
              <el-menu-item index="/nGetTreasureCard" style="min-width: 0">宝藏卡获取记录</el-menu-item>
              <el-menu-item index="/nUseTreasureCard" style="min-width: 0">宝藏卡使用记录</el-menu-item>
              <el-menu-item index="/nMemberProducts" style="min-width: 0">新会员商品</el-menu-item>
              <el-menu-item index="/bgcResetRecord" style="min-width: 0">后台充值会员</el-menu-item>
              <el-menu-item index="/bgcResetRecordCard" style="min-width: 0">后台充值宝藏卡</el-menu-item>

            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="6">
            <template slot="title">
              <i class="el-icon-document"></i>
              <span>分享邀请</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/recommend" style="min-width: 0">邀请查询</el-menu-item>
              <el-menu-item index="/recommendNum" style="min-width: 0">邀请数量</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="7">
            <template slot="title">
              <i class="el-icon-document"></i>
              <span>渠道</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/channelList" style="min-width: 0">渠道列表</el-menu-item>
<!--              <el-menu-item index="/channelStatistics" style="min-width: 0">渠道统计</el-menu-item>-->
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="/like">
            <i class="el-icon-s-flag"></i>
            <span slot="title">喜欢记录</span>
          </el-menu-item>
          <el-menu-item index="/watchNum">
            <i class="el-icon-s-check"></i>
            <span slot="title">每日浏览次数</span>
          </el-menu-item>
          <el-menu-item index="/logoff">
            <i class="el-icon-delete-solid"></i>
            <span slot="title">注销记录</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div style="float: left;position: absolute;" :class='isCollapse == true ? "rg94" : "rg88"'>
        <!--   退出登录     -->
        <el-card body-style="height:20px;display: flex;align-items: center;margin-left:-20px;justify-content: space-between;font-size:16px">
          <div class="log-out">
            <div style="padding: 0  0px 0px 15px;display: flex;align-items: center" @click="aa">
              <svg
                  :class="{'is-active':isActive}"
                  class="hamburger"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  width="64"
                  height="64"
              >
                <path d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z" />
              </svg>
            </div>
            <Breadcrumb></Breadcrumb>
          </div>
          <el-dropdown>
            <span class="el-dropdown-link">
              <img src="https://findboy.oss-cn-hangzhou.aliyuncs.com/icon/erha.jpeg" class="icon">
              <i class="el-icon-caret-bottom"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <router-link to="/UserRegister">
                <el-dropdown-item>首页</el-dropdown-item>
              </router-link>
              <el-dropdown-item divided @click.native="logOut">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-card>
        <router-view></router-view>
      </div>
  </div>
</template>

<script>
import {useIndex} from "@/libs/utilsDram.js"
import { removeToken } from "../https";
import breadCrumb from '@/components/breadCrumb/index'

export default {
  name: "Main.vue",
  data() {
    return {
      windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
      isCollapse:true,
      isActive: false,
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    aa(){
      this.isActive = this.isActive === true ? false : true;
      this.isCollapse = this.isCollapse === true ? false : true
    },
    //退出登录
    logOut(){
       removeToken();
       localStorage.removeItem('findBoy')
       this.$router.push('/login')
    }
  },
  mounted() {
    const {calcRate, windowDraw} = useIndex(this.$refs.appRef)
    calcRate()
    windowDraw()
  },

  components:{
    'Breadcrumb':breadCrumb
  },
}
</script>

<style scoped lang="less">
.a{
  text-decoration: none;
}
.router-link-active {
  text-decoration: none;
}
.el-dropdown-link {
  cursor: pointer;
  display: flex;
  align-items: baseline;
  .icon{
    width: 35px;
    height: 35px;
    border-radius: 5px;
    margin-right: 5px;

  }
}
.el-icon-arrow-down {
  font-size: 12px;
}
.main {
  color: #d3d6dd;
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left top;


}
.lf10{
  width:200px;
}
.lf5{
  width: 60px;
}
.rg88{
  width:1680px;
  margin-left: 200px
}
.rg94{
  width:1800px;
  //transition: width 2s;
  margin-left: 70px
}
.layout-container {
  width: 100%;
  height: 100%;
}
.log-out{
  width: 100%;
  background-color: #fff;
  height: 50px;
  display: flex;
  align-items: center;
}
.hamburger {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.hamburger.is-active {
  transform: rotate(180deg);
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}

</style>
