<template>
  <div>
    <el-card>
      <el-row :gutter="24">
        <el-col :span="1.5">
          <div>用户Id：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="userInfo.id"></el-input>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>审核状态：</div>
        </el-col>
        <el-col :span="4">
          <el-select v-model="userInfo.perfect" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>人为审核状态：</div>
        </el-col>
        <el-col :span="4">
          <el-select v-model="userInfo.human_review_status" placeholder="请选择">
            <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" @click="userInfo.pageNum=1,showVerify()">查询
        </el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column label="优质用户" width="80">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.good_user_sign" true-label="1" false-label="0"
                         @change="changeGoodUserSign(scope.row.id,parseInt(scope.row.good_user_sign))"/>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                type="primary">编辑
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="审核是否通过" width="160">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="primary"
                @click="changeVerify(scope.$index, scope.row,1,reason)">通过
            </el-button>
            <el-popover
                placement="right"
                width="160"
                style="margin-left: 10px"
                trigger="hover"
                v-model="scope.row.visible">
              <p>请选择未通过原因</p>
              <div style="text-align: left; margin: 0">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox label="非本人照片"></el-checkbox>
                  <el-checkbox label="照片不真实"></el-checkbox>
                  <el-checkbox label="交友信息未完善"></el-checkbox>
                  <el-checkbox label="资料含有色情内容"></el-checkbox>
                  <el-checkbox label="资料含有广告内容"></el-checkbox>
                </el-checkbox-group>
                <br>
                <el-button type="primary"
                           size="mini"
                           @click="changeVerify(scope.$index, scope.row,3,checkList),scope.row.visible = false">
                  确定
                </el-button>
              </div>
              <el-button slot="reference" size="mini" type="danger">不通过</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="perfect" label="审核状态" width="100"/>
        <el-table-column prop="id" label="编号" width="80"/>
        <el-table-column prop="nickName" label="昵称" width="100"/>
        <el-table-column prop="avatarUrl" label="头像" width="70">
          <template slot-scope="scope">
            <el-avatar :src="scope.row.avatarUrl"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="age" label="年龄" width="50"/>
        <el-table-column prop="height" label="身高" width="50"/>
        <el-table-column prop="selfPicture" label="自拍">
          <template slot-scope="scope">
            <span v-for="(item,index) in (scope.row.selfPicture||'').split(',')" :key="index">
              <el-popover placement="left" trigger="click" width="300">
                <img :src="item" width="100%"/>
                <img
                    slot="reference"
                    :src="item"
                    :alt="item"
                    style="max-height: 70px;max-width: 70px; padding: 5px"
                />
              </el-popover>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="introduction" label="自我介绍" width="200"/>
        <el-table-column prop="hobby" label="爱好" width="200"/>
        <el-table-column prop="objective" label="交友目的" width="200"/>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="userInfo.pageNum"
          :page-sizes="[10, 50, 100, 500]"
          :page-size="userInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
    <el-dialog title="修改用户信息" :visible.sync="dialogFormVisible" center width="1000px" :modal-append-to-body="false">
      <el-form :model="userInfoModel">
        <el-row>
          <el-col :span="4">
            <el-form-item label="用户Id" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.id" style="width: 100px;" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="昵称" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.nickName" style="width: 160px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="手机号" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.phone" style="width: 120px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="头像" :label-width="formLabelWidth">
              <el-upload
                  class="avatar-uploader"
                  action="http://fbadmin.laobitang.com/v2/userInfo/uploadPicture"
                  name="file"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload">
                <img v-if="userInfoModel.avatarUrl" :src="userInfoModel.avatarUrl" class="avatar" alt="" :size="50">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <el-form-item label="省份" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.province" style="width: 100px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="城市" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.city" style="width: 100px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="类型" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.type" placeholder="类型" style="width: 80px">
                <el-option label="0" value="0"></el-option>
                <el-option label="1" value="1"></el-option>
                <el-option label="0.5" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="年龄" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.age" style="width: 50px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="身高" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.height" style="width: 70px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="学历" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.edu" placeholder="学历" style="width: 200px">
                <el-option label="其他" value="0"></el-option>
                <el-option label="大专" value="1"></el-option>
                <el-option label="本科" value="2"></el-option>
                <el-option label="硕士" value="3"></el-option>
                <el-option label="硕士及以上" value="4"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="收入" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.inCome" placeholder="学历" style="width: 200px">
                <el-option label="其他" value="0"></el-option>
                <el-option label="10W以内" value="1"></el-option>
                <el-option label="10W~20W" value="2"></el-option>
                <el-option label="20W~30W" value="3"></el-option>
                <el-option label="50W以上" value="4"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="标签" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.tag" style="width: 200px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :show-overflow-tooltip="true" :span="15">
          <el-form-item label="自拍" :label-width="formLabelWidth">
            <el-col v-for="imgList in userInfoModel.imgList" :key="imgList" :span="3">
              <div class="demo-image__preview">
                <el-image
                    style="width: 80px; height: 80px"
                    :src="imgList"
                    @click="delImg(userInfoModel.imgList.indexOf(imgList))">
                </el-image>
              </div>
            </el-col>
            <el-col>
              <el-upload
                  class="avatar-uploader"
                  action="http://fbadmin.laobitang.com/v2/userInfo/uploadPicture"
                  :show-file-list="false"
                  :on-success="handleImgSuccess"
                  :before-upload="beforeAvatarUpload">
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-col>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="自我介绍" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.introduction" style="width: 200px" type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="爱好" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.hobby" style="width: 200px" type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="目的" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.objective" style="width: 200px" type="textarea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="信息完善" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.perfect" style="width: 120px">
                <el-option label="未完善" value="0"></el-option>
                <el-option label="完善" value="1"></el-option>
                <el-option label="提交审核中" value="2"></el-option>
                <el-option label="审核未通过" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="活跃度" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.hot" style="width: 120px" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeUserInfo(userInfoModel)">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import {changeUserInfo, changeVerify, showUserInfoById, showVerify, premiumUsers} from "@/https";

export default {
  name: "UserVerify",
  data() {
    return {
      true_label: 1,
      false_label: 0,
      userInfo: {
        id: "",
        perfect: "",
        human_review_status: "",
        pageNum: 1,
        limit: 10
      },
      userInfoModel: {
        id: "",
        nickName: "",
        avatarUrl: "",
        phone: "",
        province: "",
        city: "",
        type: "",
        age: "",
        height: "",
        edu: "",
        inCome: "",
        selfPicture: "",
        tag: "",
        introduction: "",
        hobby: "",
        objective: "",
        perfect: "",
        hot: "",
        imgList: []
      },
      options: [{
        value: '0',
        label: '资料不完善'
      }, {
        value: '1',
        label: '审核通过'
      }, {
        value: '2',
        label: '提交审核中'
      }, {
        value: '3',
        label: '审核未通过'
      }],
      options2: [{
        value: '0',
        label: '资料不完善'
      }, {
        value: '1',
        label: '审核通过'
      }],
      checkList: [],
      reason: "资料审核通过",
      dialogTableVisible: false,
      dialogFormVisible: false,
      dialogImageUrl: '',
      dialogVisible: false,
      formLabelWidth: '120px',
      tableData: [],
      total: 0,
      file: ''
    }
  },
  methods: {

    aa(selection, row) {
      console.log(selection, row, 'selection, row')
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.userInfo.limit = val
      this.showVerify()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.userInfo.pageNum = val
      this.showVerify()
    },
    handleImgSuccess(res) {
      if (!this.userInfoModel.imgList) {
        this.userInfoModel.imgList = [];
        this.userInfoModel.imgList.push(res.key);
      } else if (this.userInfoModel.imgList[0] === '') {
        this.userInfoModel.imgList = [];
        this.userInfoModel.imgList.push(res.key);
      } else {
        this.userInfoModel.imgList.push(res.key);
      }
    },
    handleAvatarSuccess(res) {
      this.userInfoModel.avatarUrl = res.key
    },
    beforeAvatarUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isLt10M) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return isLt10M;
    },
    delImg(index) {
      this.userInfoModel.imgList.splice(index, 1);
    },
    resetButtonValue() {
      this.userInfo.id = ''
      this.userInfo.perfect = ''
      this.userInfo.pageNum = 1
      this.userInfo.limit = 10
      this.showVerify()
    },
    showVerify() {
      this.tableData = []
      this.spinShow = true
      console.log(this.userInfo.perfect)
      return new Promise(() => {
        showVerify({
          id: this.userInfo.id,
          human_review_status: this.userInfo.human_review_status,
          perfect: this.userInfo.perfect,
          pageNum: this.userInfo.pageNum,
          limit: this.userInfo.limit
        }).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.list.length; i++) {
            this.tableData.push({
              id: data.list[i].id,
              nickName: data.list[i].nickName,
              good_user_sign: data.list[i].good_user_sign.toString(),
              avatarUrl: data.list[i].avatarUrl,
              age: data.list[i].age,
              height: data.list[i].height,
              selfPicture: data.list[i].selfPicture,
              introduction: data.list[i].introduction,
              hobby: data.list[i].hobby,
              objective: data.list[i].objective,
              perfect: data.list[i].perfect === 0 ? '资料未完善' : data.list[i].perfect === 1 ? '审核通过' : data.list[i].perfect === 2 ? '审核提交中' : '审核未通过',
            })
          }
          console.log(this.tableData);
        })
      })
    },
    //优质用户Api
    premiumUsersApi(data) {
      return new Promise(() => {
        premiumUsers(data).then(res => {
          console.log(res.data.code)
          if (res.data.code) {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.showVerify()
          }
        })
      })
    },
    // 改变优质用户状态
    changeGoodUserSign(userId, goodUserSign) {
      console.log(userId);
      console.log(goodUserSign);
      this.premiumUsersApi({id: userId, good_user_sign: goodUserSign})
    },
    changeVerify(index, row, verifyStatus, reason) {
      return new Promise(() => {
        changeVerify({
          id: row.id,
          reason: reason.toString(),
          perfect: verifyStatus,
        }).then(res => {
          if (res.data.code === 200) {
            this.showVerify()
          } else {
            this.open(res.data.msg)
          }
        })
      })
    },
    handleEdit(index, row) {
      this.userInfoModel.imgList = []
      return new Promise(() => {
        showUserInfoById({
          id: row.id,
        }).then(res => {
          let data = res.data.data
          this.dialogFormVisible = true
          this.userInfoModel.id = data.id
          this.userInfoModel.nickName = data.nickName
          this.userInfoModel.avatarUrl = data.avatarUrl
          this.userInfoModel.phone = data.phone
          this.userInfoModel.province = data.province
          this.userInfoModel.city = data.city
          this.userInfoModel.type = data.type === 2 ? '0.5' : data.type
          this.userInfoModel.age = data.age
          this.userInfoModel.height = data.height
          this.userInfoModel.edu = data.edu === 0 ? '其他' : data.edu === 1 ? '大专' : data.edu === 2 ? '本科' : data.edu === 3 ? '硕士' : data.edu === 4 ? '硕士及以上' : ''
          this.userInfoModel.inCome = data.inCome === 0 ? '其他' : data.inCome === 1 ? '10W以内' : data.inCome === 2 ? '10W~20W' : data.inCome === 3 ? '20W~30W' : data.inCome === 4 ? '50W以上' : ''
          this.userInfoModel.selfPicture = data.selfPicture
          this.userInfoModel.tag = data.tag
          this.userInfoModel.introduction = data.introduction
          this.userInfoModel.hobby = data.hobby
          this.userInfoModel.objective = data.objective
          this.userInfoModel.perfect = data.perfect === 0 ? '未完善' : data.perfect === 1 ? '完善' : data.perfect === 2 ? '提交审核中' : '提交审核中'
          this.userInfoModel.hot = data.hot
          this.userInfoModel.imgList = res.data.imgList === null ? [] : res.data.imgList
        })
      })
    },
    changeUserInfo() {
      return new Promise(() => {
        if (!this.userInfoModel.imgList) {
          this.userInfoModel.imgList = []
        }
        //将“，”替换成“,”，g代表全部
        let reg = new RegExp('，', 'g')
        this.userInfoModel.tag = this.userInfoModel.tag.replace(reg, ',');
        changeUserInfo({
          id: this.userInfoModel.id,
          nickName: this.userInfoModel.nickName,
          avatarUrl: this.userInfoModel.avatarUrl,
          phone: this.userInfoModel.phone,
          province: this.userInfoModel.province,
          city: this.userInfoModel.city,
          type: this.userInfoModel.type === '0.5' ? '2' : this.userInfoModel.type,
          age: this.userInfoModel.age,
          height: this.userInfoModel.height,
          edu: this.userInfoModel.edu === '其他' ? 0 : this.userInfoModel.edu === '大专' ? 1 : this.userInfoModel.edu === '本科' ? 2 : this.userInfoModel.edu === '硕士' ? 3 : this.userInfoModel.edu === '硕士及以上' ? 4 : this.userInfoModel.edu,
          inCome: this.userInfoModel.inCome === '其他' ? 0 : this.userInfoModel.inCome === '10W以内' ? 1 : this.userInfoModel.inCome === '10W~20W' ? 2 : this.userInfoModel.inCome === '20W~30W' ? 3 : this.userInfoModel.inCome === '50W以上' ? 4 : this.userInfoModel.inCome,
          tag: this.userInfoModel.tag,
          introduction: this.userInfoModel.introduction,
          hobby: this.userInfoModel.hobby,
          objective: this.userInfoModel.objective,
          perfect: this.userInfoModel.perfect === '未完善' ? 0 : this.userInfoModel.perfect === '完善' ? 1 : this.userInfoModel.perfect === '提交审核中' ? 2 : 3,
          hot: this.userInfoModel.hot,
          selfPicture: this.userInfoModel.imgList.toString()
        }).then(res => {
          if (res.data.code === 200) {
            this.dialogFormVisible = false
            this.showVerify()
          } else {
            this.open(res.data.msg)
          }
        })
      })
    },
    open(msg) {
      this.$message.error(msg)
    }
  },
  mounted: function () {
    this.showVerify();
  }
}
</script>

<style scoped>
.el-upload--picture-card {
  width: 50px;
  height: 50px;
}

.el-upload {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.avatar {
  width: 70px;
  height: 70px;
}

.el-dialog {
  transform: translateY(-50%);
  margin-top: 0 !important;
  top: 50%;
}
</style>
