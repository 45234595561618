<template>
  <div>
    <el-card>
      <el-row :gutter="24" type="flex" align="middle">
        <el-col :span="1.5">
          <div>每日:</div>
        </el-col>
        <el-col :span="2">
          <el-date-picker
              v-model="day"
              type="date"
              size="small"
              placeholder="选择日期">
          </el-date-picker>
        </el-col>
        <el-col :span="1.5">
          <div>每月:</div>
        </el-col>
        <el-col :span="2">
          <el-date-picker
              v-model="month"
              type="month"
              size="small"
              placeholder="选择月">
          </el-date-picker>
        </el-col>
        <el-col :span="1.5">
          <div>时间范围:</div>
        </el-col>
        <el-col :span="3">
          <el-input  size="small"></el-input>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" >查询</el-button>
        <el-button style="margin-left: 20px" >重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      13
    </el-card>
  </div>
</template>

<script>
export default {
  name: "channelStatistics",
  data(){
    return {
      month:'',
      day:''
    }
  }
}
</script>

<style scoped>

</style>
