<template>
  <div>
    <el-card>
      <el-row :gutter="22">
        <el-col :span="3">
          <el-select v-model="query_day.type">
            <el-option key="month" label="每月统计" value="month">
            </el-option>
            <el-option key="day" label="每日统计" value="day">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3.5">
          <el-date-picker
              value-format="timestamp"
              v-model="query_day.date"
              :type="query_day.type === 'day' ? 'date' : 'month'"
              :placeholder="query_day.type === 'day' ? '日期选择' : '月份选择'">
          </el-date-picker>
        </el-col>
        <el-col :span="7">
          <el-button style="margin-left: 20px" type="primary" @click="query">查询</el-button>
          <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
        </el-col>

      </el-row>
    </el-card>
    <el-card>
      <el-table border style="width: 100%" :data="list_data">
        <el-table-column prop="price" label="金额"/>
        <el-table-column label="时间" prop="pay_month">

<!--          <template slot-scope="scope">-->
<!--            <div>-->
<!--              -->
<!--            </div>-->
<!--          </template>-->
        </el-table-column>
      </el-table>

    </el-card>
  </div>
</template>

<script>
import { privateOrder} from "../https";
import dayjs from "dayjs";

export default {
  name: "privateOrderReven",
  data(){
    return{
      list_data:[],
      query_day: {
        type: "month",
        date: null,
        pageNum: 1,
        limit: 10,
      },//查询数据
      myDayJs:dayjs,
    }
  },
  methods:{
    query(){
      // 定义查询条件
      let ty = ''
      if (this.query_day.type == 'day') {
        ty = dayjs(this.query_day.date).format('YYYY-MM-DD')
        this.privateOrderApi({
          type: this.query_day.type,
          pay_day:ty,
          pageNum: 1,
          limit: 10,
        })
      }else {
        ty = dayjs(this.query_day.date).format('YYYY-MM')
        this.privateOrderApi({
          type: this.query_day.type,
          pay_month:ty,
          pageNum: 1,
          limit: 10,
        })
      }


      console.log(this.query_day.type,ty)
    },
    resetButtonValue(){},
    privateOrderApi(data){
      return new Promise(() => {
        privateOrder(data).then(res => {
          this.list_data = res.data.data.list
          console.log(res.data.data);
        })
      })
    },
  },
  beforeMount() {

    this.privateOrderApi(this.query_day)
  }
}
</script>

<style scoped>

</style>
