<template>
  <div>
    <el-card>
      <el-row :gutter="22">
        <el-col :span="3">
          <el-select v-model="time_type">
            <el-option key="month" label="每月统计" value="month">
            </el-option>
            <el-option key="day" label="每日统计" value="day">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-date-picker
              v-model="query_day.date"
              :type="time_type === 'day' ? 'date' : 'month'"
              :placeholder="time_type === 'day' ? '日期选择' : '月份选择'">
          </el-date-picker>
        </el-col>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-button type="primary" @click="query">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table border style="width: 100%" :data="list_data.list">
        <el-table-column prop="id" label="编号" width="80"/>
        <el-table-column prop="price" label="总金额"/>
        <el-table-column prop="si_price" label="私人订制金额"/>
        <el-table-column prop="si_num" label="私人订制数量"/>
        <el-table-column prop="one_vip_num" label="一个月普通会员数量"/>
        <el-table-column prop="one_vip_price" label="一个月普通会员金额"/>
        <el-table-column prop="three_vip_num" label="三个月普通会员数量"/>
        <el-table-column prop="three_vip_price" label="三个月普通会员金额"/>
        <el-table-column prop="year_vip_num" label="年费普通会员数量"/>
        <el-table-column prop="year_vip_price" label="年费普通会员金额"/>
        <el-table-column prop="one_black_vip_num" label="一个月黑钻会员数量"/>
        <el-table-column prop="one_black_vip_price" label="一个月黑钻会员金额"/>
        <el-table-column prop="three_black_vip_num" label="三个月黑钻会员数量"/>
        <el-table-column prop="three_black_vip_price" label="三个月黑钻会员金额"/>
        <el-table-column prop="year_black_vip_num" label="年费黑钻会员数量"/>
        <el-table-column prop="year_black_vip_price" label="年费黑钻会员金额"/>
        <el-table-column prop="weekend_num" label="周末夜话数量"/>
        <el-table-column prop="weekend_price" label="周末夜话金额"/>
        <el-table-column prop="tong_num" label="同城卡数量"/>
        <el-table-column prop="tong_price" label="同城卡金额"/>
        <el-table-column prop="bao_num" label="曝光卡数量"/>
        <el-table-column prop="bao_price" label="曝光卡金额"/>

        <el-table-column label="统计日期">
          <template slot-scope="scope">
            <div>
              {{ scope.row.day ? scope.row.year + '年' + scope.row.month + '月' + scope.row.day + '日' : scope.row.year + '年' + scope.row.month + '月' }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="query_day.pageNum"
          :page-size="query_day.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="list_data.totalCount">
      </el-pagination>
    </el-card>

  </div>
</template>

<script>
import {incomeStatistics} from "../https";

export default {
  name: "nOrderRevenue",
  data() {
    return {
      time_type: "month",
      query_day: {
        date: '',
        pageNum: 1,
        limit: 10,
      },//查询数据
      // total_count: 20,
      list_data: {}
    }
  },
  methods: {
    //重置
    resetButtonValue() {
      this.query_day = {
        date: '',
        pageNum: 1,
        limit: 10,
      }
    },
    //detailed_data.pageNum 改变时会触发
    handleSizeChange(val) {
      this.query_day.limit = val;
      this.query()
    },
    //detailed_data.limit 改变时会触发
    handleCurrentChange(val) {
      this.query_day.pageNum = val;
      this.query()
    },
    //查询
    query() {
      // 定义查询条件
      let query = {
        time_type: this.time_type,
        year: this.query_day.date ? new Date(this.query_day.date).getFullYear() : null,
        month: this.query_day.date ? new Date(this.query_day.date).getMonth() + 1 : null,
        day: this.time_type === 'day' && this.query_day.date ? new Date(this.query_day.date).getDate() : null,
        pageNum: this.query_day.pageNum,
        limit: this.query_day.limit,
      }
      this.orderRevenue(query)
    },
    //订单收益Api
    orderRevenue(data) {
      return new Promise(() => {
        incomeStatistics(data).then(res => {
          console.log(res);
          if (res.data.code === 200) {
            this.list_data = res.data.data
          }
        })
      })
    }
  },
  beforeMount() {
    this.query()
  }
}
</script>

<style scoped>

</style>
