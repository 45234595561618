<template>
  <div>
    <el-card>
      <el-table border style="width: 100%" :data="list_data">
        <el-table-column prop="id" label="id" />
        <el-table-column prop="title" label="标题" />
        <el-table-column prop="month" label="月" />
        <el-table-column prop="price" label="价格" />
        <el-table-column label="类型">
          <template slot-scope="scope">
            <div>
              {{ scope.row.type == "1" ? "普通会员" : "黑钻会员" }}
            </div>
          </template>
        </el-table-column>

      </el-table>
      <br>
    </el-card>
  </div>
</template>

<script>
import {newMemberProducts} from "../https";

export default {
  name: "nMemberProducts",
  data() {
    return {
      list_data: [],//所有数据
    }
  },
  methods: {
    //收益统计Api
    newMemberProductsApi() {
      return new Promise(() => {
        newMemberProducts().then(res => {
          this.list_data = res.data.data;
        })
      })
    },
  },
  beforeMount() {
    this.newMemberProductsApi()
  }
}
</script>

<style scoped>

</style>