<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">

    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item) in data_list" :key="item.path" :to="item.path" >
        <span class="no-redirect"> {{ item.meta.title }} </span>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
export default {
  name: "index",
  data(){
    return{
      data_list:[]
    }
  },
  created() {
    this.getBreadcrumb()
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  methods:{
    getBreadcrumb() {
      this.data_list = this.$route.matched.filter(item => item.meta && item.meta.title)
    }
  }
}
</script>

<style scoped lang="less">
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
.breadcrumb-enter-active,
.breadcrumb-leave-active {
  transition: all .5s;
}

.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  transform: translateX(20px);
}

.breadcrumb-move {
  transition: all .5s;
}

.breadcrumb-leave-active {
  position: absolute;
}
</style>
