<template>
  <div id="app">
    <router-view> </router-view>
  </div>
</template>

<script>





export default {
  name: 'App',
  components: {

  },
  beforeCreate() {
    // if (window.localStorage.getItem('findboyAdmin') == null){
    //   console.log('是这么', window.localStorage.getItem('findboyAdmin'))
    //
    //   this.$router.push('/Login')
    // }

  }
}
</script>

<style  scoped less="less">
#app {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #f0f0f0;
}
body{
  font-family: "微软雅黑",serif;  /*  设置字体 */
  margin: 0px auto /* 去除上下的边距*/
}
</style>
