<template>
  <div>
    <el-card>
      <el-row :gutter="22" >
        <el-col :span="1.5">
          <div>用户Id：</div>
        </el-col>
        <el-col :span="3">
          <el-input v-model="detailed_data.user_id"></el-input>
        </el-col>
        <el-col :span="1.5">
          <div>会员类别：</div>
        </el-col>
        <el-col  :span="3">
          <el-select v-model="detailed_data.vip_type" placeholder="请选择">
            <el-option
                v-for="item in member"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" @click="query">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
        <el-button style="margin-left: 20px" @click="dialogFormVisible = true" type="primary">增加会员时长</el-button>
        <el-button style="margin-left: 20px" @click="dialogFormCard = true" type="primary">增加宝藏卡</el-button>
        <el-button style="margin-left: 20px" @click="upload_micro_signal = true" type="primary">上传微信号</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table border style="width: 100%" :data="list_data">
        <el-table-column prop="id" label="订单编号" width="80"/>
        <el-table-column prop="user_id" label="用户Id" width="80"/>
        <el-table-column label="会员类别"  width="140">
          <template slot-scope="scope">
            <div>
                {{scope.row.vip_type == '0' ? '非会员' : scope.row.vip_type == '1' ? '普通会员': '黑钻会员'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="same_city_num" label="同城卡"  width="150"/>
        <el-table-column prop="exposure_num" label="曝光卡"  width="150" />
        <el-table-column label="vip截止日期"  width="350" >
          <template slot-scope="scope">
            <div>
              {{scope.row.vip_end_time == '' ? '无' : myDayJs(scope.row.vip_end_time * 1000).format("YYYY-MM-DD HH:mm:ss") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="黑钻会员截止日期"  width="350">
          <template slot-scope="scope">
            <div>
              {{scope.row.black_vip_end_time == '' ? '无' : myDayJs(scope.row.black_vip_end_time * 1000).format("YYYY-MM-DD HH:mm:ss") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column  label="是否是私人订制"  width="150" >
          <template slot-scope="scope">
            <div>
              {{ scope.row.vip_type  === '3' ? '是' : null}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="注册时间" width="160">
          <template slot-scope="scope">
            <div>
              {{myDayJs(scope.row.created_at * 1000).format("YYYY-MM-DD HH:mm:ss") }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="detailed_data.pageNum"
          :page-size="detailed_data.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total_count">
      </el-pagination>
    </el-card>
    <!--  增加会员时长  -->
    <el-dialog title="增加会员时长" :visible.sync="dialogFormVisible" center style="width: 1200px" :modal-append-to-body = "false">
      <el-form :model="user_data" style="margin: 0 20%">
        <el-form-item label="用户Id" :label-width="formLabelWidth">
          <el-input v-model="user_data.userId" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="会员类型" :label-width="formLabelWidth">
          <el-select v-model="user_data.type" placeholder="会员类型" style="width: 200px">
            <el-option label="普通会员" value="1"></el-option>
            <el-option label="黑钻会员" value="2"></el-option>
            <el-option label="私人订制" value="3"></el-option>

          </el-select>
        </el-form-item>
        <el-form-item label="增加时长(月)" :label-width="formLabelWidth">
          <el-input v-model="user_data.times" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input v-model="user_data.remarks" style="width: 200px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="userDurationBtn" >确 定</el-button>
      </div>
    </el-dialog>
    <!--  增加宝藏卡  -->
    <el-dialog title="增加宝藏卡" :visible.sync="dialogFormCard" center style="width: 1200px" :modal-append-to-body = "false">
      <el-form :model="add_card" style="margin: 0 20%">
        <el-form-item label="用户Id" :label-width="formLabelWidth">
          <el-input v-model="add_card.userId" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="会员类型" :label-width="formLabelWidth">
          <el-select v-model="add_card.type" placeholder="会员类型" style="width: 200px">
            <el-option label="同城卡" value="tong"></el-option>
            <el-option label="曝光卡" value="bao"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="增加数量(张)" :label-width="formLabelWidth">
          <el-input v-model="add_card.number" style="width: 200px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogCancel">取 消</el-button>
        <el-button type="primary" @click="addCardBtn" >确 定</el-button>
      </div>
    </el-dialog>
    <!--  添加微信号  -->
    <el-dialog title="添加微信号" :visible.sync="upload_micro_signal" center style="width: 1200px" :modal-append-to-body = "false">
      <el-form :model="add_weCard" style="margin: 0 20%">
        <el-form-item label="微信号:" :label-width="formLabelWidth">
          <el-input v-model="add_weCard.user_id" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="再一次输入:" :label-width="formLabelWidth">
          <el-input v-model="add_weCard.new_user_id" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="上传图片:" :label-width="formLabelWidth">
          <el-upload
              class="avatar-uploader"
              action="http://fbadmin.laobitang.com/v2/userInfo/uploadPicture"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
            <img v-if="add_weCard.imageUrl" :src="add_weCard.imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogCancel">取 消</el-button>
        <el-button type="primary" @click="addWeChatBtn" >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {backstageRecharge, backstageWeChat, nOpenMember, userInfo} from "../https";
import dayjs from 'dayjs'
export default {
  name: "nUserInfo",
  data(){
    return {

      type_condition: [{
        value: 'vip',
        label: '会员'
      }, {
        value: 'tong',
        label: '同城卡'
      }, {
        value: 'bao',
        label: '曝光卡'
      }],
      detailed_data:{
        user_id:'',
        vip_type:'',
        pageNum:1,
        limit:10,//每页数量
      },
      member:[{
        value: '0',
        label: '非会员用户'
      }, {
        value: '1',
        label: '普通会员用户'
      }, {
        value: '2',
        label: '黑钻会员用户'
      }],
      add_weCard: {
        user_id: '',
        new_user_id: '',
        imageUrl: '',//上传微信客服图片
      },
      list_data:[],//所有数据
      total_count:0,
      total_page:0,
      myDayJs:dayjs,
      dialogFormVisible:false,
      dialogFormCard:false,
      user_data:{
        userId:null,
        type:'',
        times:null,
        remarks:''
      },//增加时长
      add_card:{
        userId:'',
        type:'',
        number:''
      },//增加宝藏卡
      upload_micro_signal:false,//上传微信号
      formLabelWidth: '120px',
    }
  },
  methods:{
    //会员列表Api
    memberListApi(data){
      return new Promise(() => {
        userInfo(data).then(res => {
          this.list_data = res.data.data.list;
          this.total_count = res.data.data.totalCount;
          this.total_page = res.data.data.totalPage;
          console.log(res.data,this.list_data)
        })
      })
    },
    //detailed_data.pageNum 改变时会触发
    handleSizeChange(val) {
      this.detailed_data.limit = val;
      this.query()
    },
    //detailed_data.limit 改变时会触发
    handleCurrentChange(val) {
      this.detailed_data.pageNum = val
      this.query()
    },
    resetButtonValue(){
      this.detailed_data.pageNum = 1
      this.detailed_data.limit = 10
      this.detailed_data.user_id = ''
      this.detailed_data.vip_type = ''
      this.memberListApi({pageNum:1,limit:10})
    },
    //查询
    query(){
      let data = {
        user_id:this.detailed_data.user_id.length != 0 ? this.detailed_data.user_id : null,
        vip_type:this.detailed_data.vip_type.length != 0 ? this.detailed_data.vip_type : null,
        pageNum: this.detailed_data.pageNum,
        limit: this.detailed_data.limit,//每页数量
      }
      this.memberListApi(data)
    },
    //增加时长
    userDurationBtn(){
      // this.nOpenMember()
      let data = {
        recharge_user_id:this.user_data.userId,
        recharge_vip_type:this.user_data.type,
        recharge_vip_time:this.user_data.times,
        remarks:this.user_data.remarks
      }
      console.log(data)
      if (data.recharge_vip_type.length != 0 && data.recharge_vip_time.length != 0 && data.recharge_vip_time != 0 && data.remarks.length !== 0 ) {
        this.openMember(data)
      } else {
        this.$message({
          message: data.recharge_user_id == null ? '请输入id' : data.recharge_vip_type ? '请选择会员类型' : this.user_data.times == null ? '请输入时长' : '请填写备注',
          type: 'error'
        });
      }
    },
    //开通会员时长
    openMember(data){
      return new Promise(() => {
        nOpenMember(data).then(res => {
          console.log(res.data)
          if (res.data.code == 400 ){
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }else if (res.data.code == 200){
            this.dialogFormVisible = false,
                this.$message({
                  message: '修改成功',
                  type: 'success'
                });
            this.user_data.userId = ''
            this.user_data.type = ''
            this.user_data.times = ''

          }
        })
      })
    },
    //增加宝藏卡
    addCardBtn(){
      let data = {
        recharge_user_id:this.add_card.userId.length != 0 ? this.add_card.userId : null ,
        recharge_bao_type:this.add_card.type.length != 0 ? this.add_card.type : null,
        recharge_num:this.add_card.number.length != 0 ? this.add_card.number : null,
      }
      if (data.recharge_bao_type != null && data.recharge_num != null && data.recharge_user_id != null){
        this.treasureCardApi(data)
      } else  {
        this.$message({
          message: '请完善表单',
          type: 'error'
        });
      }


    },
    //增加宝藏卡Api
    treasureCardApi(data){
      return new Promise(() => {
        backstageRecharge(data).then(res => {
          if (res.data.code == 400 ){
            this.$message({
              message: res.data.msg,
              type: 'error'
            });

          }else if (res.data.code == 200){
            this.$message({
              message: '添加成功成功',
              type: 'success'
            });
            this.add_card={
              userId:'',
              type:'',
              number:''
            };//增加宝藏卡
            this.dialogFormCard = false
          }

        })
      })
    },
    //取消
    dialogCancel(){
      this.user_data={
        userId:'',
        type:'',
        times:''
      };
      this.add_card = {
        userId:'',
        type:'',
        number:''
      };//增加时长
      this.add_weCard = {
        user_id: '',
        new_user_id: '',
        imageUrl: ''
      };//上传客服二维码
      this.dialogFormCard = false;
      this.dialogFormVisible = false;
      this.upload_micro_signal =false
      console.log(this.add_card)
    },
    handleAvatarSuccess(res, file) {
      console.log(file)
      this.add_weCard.imageUrl = res.key;
    },
    beforeAvatarUpload(file) {
      console.log(file,'file')
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return isLt10M;
    },
    //上传微信号
    addWeChatBtn(){

      if (this.add_weCard.new_user_id.indexOf(" ") == -1 && this.add_weCard.user_id.indexOf(" ") == -1) {
        //没有空格
        if (this.add_weCard.user_id == this.add_weCard.new_user_id && this.add_weCard.imageUrl.length != 0){
          console.log('123')
          let data = {
            field1:this.add_weCard.imageUrl,
            field2:this.add_weCard.user_id
          }
          console.log(data)
          this.backstageWeChatApi(data)
        }else if (this.add_weCard.user_id != this.add_weCard.new_user_id){
          this.$message({
            message: '两次微信号不一样',
            type: 'error'
          });
        } else if (this.add_weCard.imageUrl.length == 0 ) {
          this.$message({
            message: '请上传图片',
            type: 'error'
          });
        }
      } else {
        this.$message({
          message: '请检查两次微信号中是否含有空格',
          type: 'error'
        });
      }
    },
    //上传微信客服Api
    backstageWeChatApi(data){
      return new Promise(() => {
        backstageWeChat(data).then(res => {
          if (res.data.code == 200){
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.dialogCancel()
          }
        })
      })
    },
  },
  beforeMount() {
    this.query()
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #c0ccda;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
