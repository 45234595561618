<template>
  <div>
    <el-card>
      <el-row :gutter="24">
        <el-col :span="1.5">
          <div>用户Id：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="watchNum.userId"></el-input>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" @click="watchNum.pageNum=1,showWatchNum()">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="userId" label="用户Id" width="150"/>
        <el-table-column prop="visitNum" label="今日浏览数量" width="150"/>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="watchNum.pageNum"
          :page-sizes="[10, 50, 100, 500]"
          :page-size="watchNum.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import {showWatchNum} from "@/https";

export default {
  name: "WatchNum",
  data() {
    return {
      watchNum: {
        userId: "",
        pageNum: 1,
        limit: 10
      },
      tableData: [],
      total: 0
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.watchNum.limit = val
      this.showWatchNum()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.watchNum.pageNum = val
      this.showWatchNum()
    },
    resetButtonValue() {
      this.watchNum.userId = ''
      this.watchNum.pageNum = 1
      this.watchNum.limit = 10
      this.showWatchNum(1)
    },
    showWatchNum() {
      this.tableData = []
      this.spinShow = true
      return new Promise(() => {
        showWatchNum({
          userId: this.watchNum.userId,
          pageNum: this.watchNum.pageNum,
          limit: this.watchNum.limit
        }).then(res => {

          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.list.length; i++) {
            this.tableData.push({
              userId: data.list[i].userId,
              visitNum: data.list[i].visitNum
            })
          }
        })
      })
    }
  },
  mounted: function () {
    this.showWatchNum();
  }
}
</script>

<style scoped>

</style>