<template>
  <div>
    <el-card>
      <el-row :gutter="22">
        <el-col :span="1.5">
          <div>充值用户id：</div>
        </el-col>
        <el-col  :span="3">
          <el-input v-model="detailed_data.id"></el-input>
        </el-col>

        <el-button style="margin-left: 20px" type="primary" @click="query">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table border style="width: 100%" :data="list_data">
        <el-table-column prop="id" label="id" width="80"/>
        <el-table-column prop="recharge_user_id" label="充值用户id" width="80"/>
        <el-table-column label="充值会员类型">
          <template slot-scope="scope">
            <div>
              {{ scope.row.recharge_vip_type == "1" ? "普通会员" : "黑钻会员" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="recharge_vip_time" label="充值时长" width="80"/>
        <el-table-column label="时间">
          <template slot-scope="scope">
            <div>
              {{myDayJs(scope.row.created_at * 1000).format("YYYY-MM-DD HH:mm:ss") }}

            </div>
          </template>

        </el-table-column>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="detailed_data.pageNum"
          :page-size="detailed_data.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total_count">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { resetRecord,resetRecordPrivate} from "../https";
import dayjs from 'dayjs'

export default {
  name: "bgcResetRecord",
  data() {
    return {
      statistics:[{
        value: 'all',
        label: '所有'
      }, {
        value: 'vip',
        label: '会员'
      }, {
        value: 'bao',
        label: '曝光卡'
      },{
        value: 'tong',
        label: '同城卡'
      }],
      statistics_type:'',
      member:[{
        value: '0',
        label: '非会员用户'
      }, {
        value: '1',
        label: '普通会员用户'
      }, {
        value: '2',
        label: '黑钻会员用户'
      }],
      member_type:'',
      type_condition: [{
        value: 'vip',
        label: '会员'
      }, {
        value: 'tong',
        label: '同城卡'
      }, {
        value: 'bao',
        label: '曝光卡'
      }],
      detailed_data: {
        id:'',
        pageNum: 1,
        limit: 10,//每页数量
      },
      list_data: [],//所有数据,
      private_list_data:[],//私人订制数据
      total_count: 0,
      total_page: 0,
      myDayJs: dayjs,
      time:[{
        value: 'month',
        label: '月统计'
      }, {
        value: 'day',
        label: '每日统计'
      }],
    }
  },
  methods: {
    //收益统计Api
    memberListApi(data) {
      return new Promise(() => {
        resetRecord(data).then(res => {

          this.list_data = res.data.data.list;
          console.log(this.list_data)
          this.total_count = res.data.data.totalCount;
          this.total_page = res.data.data.totalPage;
        })
      })
    },

    //私人订制Api
    async resetRecordPrivateApi(data){
      await resetRecordPrivate(data).then(res=>{
        this.private_list_data = res.data.data.list
        console.log(res.data.data.list,'这')
      })
    },
    //detailed_data.pageNum 改变时会触发
    handleSizeChange(val) {
      this.detailed_data.limit = val;
      this.memberListApi(this.detailed_data)
    },
    //detailed_data.limit 改变时会触发
    handleCurrentChange(val) {
      this.detailed_data.pageNum = val
      this.memberListApi(this.detailed_data)
    },
    //重置
    resetButtonValue(){
      this.detailed_data.id = ''
      this.detailed_data.pageNum = 1
      this.detailed_data.limit = 10
      this.memberListApi({pageNum:1,limit:10})
    },
    //查询
    query() {
      let data = {
        recharge_user_id:this.detailed_data.id.length != 0 ? this.detailed_data.id : null,
        pageNum: this.detailed_data.pageNum,
        limit: this.detailed_data.limit,//每页数量
      }
      this.memberListApi(data)
      this.resetRecordPrivateApi(data)
    },
  },
  beforeMount() {
    this.query()
  }
}
</script>

<style scoped>

</style>
