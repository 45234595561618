<template>
  <div>
    <el-card>
      <el-row :gutter="24">
        <el-col :span="1.5">
          <div>用户Id：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="perfectVip.userId"></el-input>
        </el-col>
        <el-col :span="1.5">
          <div>领取时间：</div>
        </el-col>
        <el-col :span="7">
          <el-date-picker
              v-model="value1"
              type="daterange"
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" @click="perfectVip.pageNum=1,showPerfectVip()">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="id" label="编号" width="80"/>
        <el-table-column prop="userId" label="用户Id" width="80"/>
        <el-table-column prop="createAt" label="领取时间" width="160"/>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="perfectVip.pageNum"
          :page-sizes="[10, 50, 100, 500]"
          :page-size="perfectVip.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import {showPerfectVip} from "@/https";
import {formatDateTime} from "@/libs/tools";

export default {
  name: "PerfectVip.vue",
  data() {
    return {
      perfectVip: {
        userId: "",
        pageNum: 1,
        limit: 10
      },
      tableData: [],
      total: 0,
      value1: ''
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.perfectVip.limit = val
      this.showPerfectVip()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.perfectVip.pageNum = val
      this.showPerfectVip()
    },
    resetButtonValue() {
      this.perfectVip.userId = ''
      this.value1 = ''
      this.perfectVip.pageNum = 1
      this.perfectVip.limit = 10
      this.showPerfectVip()
    },
    showPerfectVip() {
      this.tableData = []
      return new Promise(() => {
        showPerfectVip({
          userId: this.perfectVip.userId,
          createAtStart: this.value1[0],
          createAtEnd: this.value1[1],
          pageNum: this.perfectVip.pageNum,
          limit: this.perfectVip.limit
        }).then(res => {
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.list.length; i++) {
            this.tableData.push({
              id: data.list[i].id,
              userId: data.list[i].userId,
              createAt: formatDateTime(data.list[i].createAt * 1000)
            })
          }
        })
      })
    }
  },
  mounted: function () {
    this.showPerfectVip();
  }
}
</script>

<style scoped>

</style>