<template>
  <div>
    <el-card>
      <el-row :gutter="24">
        <el-col :span="1.5">
          <div>帖子Id：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="post.postId"></el-input>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>用户Id：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="post.userId"></el-input>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>帖子状态：</div>
        </el-col>
        <el-col :span="2">
          <el-select v-model="post.status" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" @click="post.pageNum=1,showPost()">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-button type="primary" @click="openDialog">添加帖子</el-button>
      <br><br>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="postId" label="帖子Id" width="80"/>
        <el-table-column prop="userId" label="用户Id" width="80"/>
        <el-table-column prop="words" label="文字内容"/>
        <el-table-column prop="imgs" label="图片内容">
          <template slot-scope="scope">
            <span v-for="(item,index) in (scope.row.imgs||'').split(',')" :key="index">
              <el-popover placement="left" trigger="click" width="300">
                <img :src="item" width="100%"/>
                <img
                    slot="reference"
                    :src="item"
                    :alt="item"
                    style="max-height: 70px;max-width: 70px; padding: 5px"
                />
              </el-popover>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="favNum" label="点赞数" width="70"/>
        <el-table-column prop="commentNum" label="评论数" width="70"/>
        <el-table-column prop="status" label="帖子状态" width="80">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="primary"
                @click="changePostStatus(scope.$index, scope.row)"
                v-if="scope.row.status==='正常'"
                v-text="scope.row.status">
            </el-button>
            <el-button
                size="mini"
                @click="changePostStatus(scope.$index, scope.row)"
                v-if="scope.row.status==='删除'"
                type="danger"
                v-text="scope.row.status">
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="createAt" label="创建时间" width="160"/>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="post.pageNum"
          :page-sizes="[10, 50, 100, 500]"
          :page-size="post.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
    <el-dialog title="添加帖子" :visible.sync="dialogFormVisible" center width="800px" :modal-append-to-body="false">
      <el-form :model="form">
        <el-form-item label="用户Id" :label-width="formLabelWidth">
          <el-input v-model="form.userId" style="width: 100px"></el-input>
        </el-form-item>
        <el-form-item label="文字内容" :label-width="formLabelWidth">
          <el-input type="textarea" placeholder="请输入内容" v-model="form.words" style="width: 500px" autosize></el-input>
        </el-form-item>
        <el-form-item label="图片" :label-width="formLabelWidth">
          <el-col v-for="imgs in form.imgs" :key="imgs" :span="4">
            <div class="demo-image__preview">
              <el-image
                  style="width: 80px; height: 80px"
                  :src="imgs"
                  @click="delImg(form.imgs.indexOf(imgs))">
              </el-image>
            </div>
          </el-col>
          <el-col>
            <el-upload
                class="avatar-uploader"
                action="http://fbadmin.laobitang.com/v2/userInfo/uploadPicture"
                :show-file-list="false"
                :on-success="handleImgSuccess">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addPost">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {addPost, changePostStatus, showPost} from "@/https";
import {formatDateTime} from "@/libs/tools";

export default {
  name: "Post.vue",
  data() {
    return {
      post: {
        postId: "",
        userId: "",
        status: "",
        pageNum: 1,
        limit: 10
      },
      options: [{
        value: '0',
        label: '正常'
      }, {
        value: '1',
        label: '删除'
      }],
      form: {
        userId: '',
        words: '',
        imgs: ''
      },
      dialogTableVisible: false,
      dialogFormVisible: false,
      formLabelWidth: '200px',
      tableData: [],
      total: 0
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.post.limit = val
      this.showPost()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.post.pageNum = val
      this.showPost()
    },
    resetButtonValue() {
      this.post.postId = ''
      this.post.userId = ''
      this.post.status = ''
      this.post.pageNum = 1
      this.post.limit = 10
      this.showPost()
    },
    openDialog() {
      this.form.userId = ''
      this.form.words = ''
      this.form.imgs = ''
      this.dialogFormVisible = true
    },
    handleImgSuccess(res) {
      if (!this.form.imgs) {
        this.form.imgs = [];
        this.form.imgs.push(res.key);
      } else if (this.form.imgs[0] === '') {
        this.form.imgs = [];
        this.form.imgs.push(res.key);
      } else {
        this.form.imgs.push(res.key);
      }
    },
    delImg(index) {
      this.form.imgs.splice(index, 1);
    },
    showPost() {
      this.tableData = []
      this.spinShow = true
      return new Promise(() => {
        showPost({
          postId: this.post.postId,
          userId: this.post.userId,
          status: this.post.status,
          pageNum: this.post.pageNum,
          limit: this.post.limit
        }).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.list.length; i++) {
            this.tableData.push({
              postId: data.list[i].postId,
              userId: data.list[i].userId,
              words: data.list[i].words,
              imgs: data.list[i].imgs,
              favNum: data.list[i].favNum,
              commentNum: data.list[i].commentNum,
              status: data.list[i].status === 0 ? '正常' : '删除',
              createAt: formatDateTime(data.list[i].createAt * 1000),
            })
          }
        })
      })
    },
    changePostStatus(index, row) {
      return new Promise(() => {
        changePostStatus({
          postId: row.postId
        }).then(res => {
          if (res.data.code === 200) {
            row.status = (row.status === '正常' ? '删除' : '正常')
          }else {
            this.open(res.data.msg)
          }
        })
      })
    },
    addPost() {
      return new Promise(() => {
        addPost({
          userId: this.form.userId,
          words: this.form.words,
          imgs: this.form.imgs.toString()
        }).then(res => {
          if (res.data.code === 200) {
            this.dialogFormVisible = false
            this.showPost()
          } else {
            this.open(res.data.msg)
          }
        })
      })
    },
    open(msg) {
      this.$message.error(msg)
    }
  },
  mounted: function () {
    this.showPost();
  }
}
</script>

<style scoped>

</style>