<template>
  <div>
    <el-card>
      <el-row :gutter="16" type="flex" align="middle">
        <el-col :span="1.5">
          <div>用户id：</div>
        </el-col>
        <el-col  :span="3">
          <el-input v-model="private_data.userId"></el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="private_data.state" placeholder="请选择" >
            <el-option
                v-for="item in options"
                :key="item.state"
                :label="item.label"
                :value="item.state" >
            </el-option>
          </el-select>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" @click="query">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
        <el-button style="margin-left: 20px" type="primary" @click="upload_micro_signal = true">上传微信图片</el-button>

      </el-row>
    </el-card>
    <el-card>
      <el-table border style="width: 100%" :data="list_data.list">
        <el-table-column prop="id" label="编号" width="80"/>
        <el-table-column prop="user_id" label="用户Id"/>
        <el-table-column prop="phone" label="手机号"/>
        <el-table-column label="处理">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.sign" :true-label=1 :false-label=0
                         @change="changeGoodUserSign(scope.row.id,scope.row.sign)"/>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="金额"/>
        <el-table-column label="支付时间">
          <template slot-scope="scope">
            <div>
              {{ scope.row.pay_time == '' ? '' : myDayJs(scope.row.pay_time * 1000).format("YYYY-MM-DD HH:mm:ss")  }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="支付状态">
          <template slot-scope="scope">
            <div>
              {{ scope.row.state == 0 ? '未支付' : '已支付 ' }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleCurrentChange"
          @current-change="handleCurrentChange"
          :current-page="list_data.pageNum"
          :page-size="list_data.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="list_data.totalCount">
      </el-pagination>
    </el-card>
    <el-dialog title="更换私人客服微信号" :visible.sync="upload_micro_signal" center style="width: 1200px" :modal-append-to-body = "false">
      <el-form  style="margin: 0 20%">
        <el-form-item label="上传微信图片:">
          <el-upload
              class="avatar-uploader"
              :action='action'
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
            <img v-if="field1" :src="field1" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="upload_micro_signal = false,field1=''">取 消</el-button>
        <el-button type="primary" @click="addWeChatBtn" >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {privateCustomizedPhone, privatePhoneHandle, privateCustomer} from "../https";
import dayjs from "dayjs";
import axios from "axios";
// import axios from "axios";
export default {
  name: "privatePhone",
  data(){
    return {
      private_data:{
        userId:null,
        pageNum: 1,
        limit: 10,
        state:1,
      },
      list_data:[],
      myDayJs: dayjs,
      options:[{
        state: '1',
        label: '已支付'
      }, {
        state: '0',
        label: '未支付'
      }],
      upload_micro_signal:false,
      field1:'',
      action:axios.defaults.baseURL + '/v2/userInfo/uploadPicture'
    }
  },
  methods:{
    //私人订制电话Api
    privatePhoneApi(data) {
      return new Promise(() => {
        privateCustomizedPhone(data).then(res => {
          console.log(res.data.data);
          this.list_data = res.data.data;

        })
      })
    },
    //私人客服Api
    privateCustomerApi(data) {
      return new Promise(() => {
        privateCustomer(data).then(res => {
          console.log(res.data.code);
          if ( res.data.code == 200 ) {
            this.$message({
              message: res.data.data,
              type: 'success'
            })
            this.field1 = '';
            this.upload_micro_signal = false;
          }
        })
      })
    },
    handleAvatarSuccess(res, file) {
      console.log(file)
      this.field1 = res.key;
    },
    beforeAvatarUpload(file) {
      console.log(file,'file')
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return isLt10M;
    },
    //处理电话Api
    processCallsApi(data) {
      return new Promise(() => {
        privatePhoneHandle(data).then(res => {
          console.log(res.data);
          if (res.data.code == 200){
            this.$message({
              message: res.data.data,
              type: 'success'
            })
            this.privatePhoneApi(this.private_data)
          }
        })
      })
    },
    handleSizeChange(e){
      console.log(e)
    },
    handleCurrentChange(e){
      this.private_data.pageNum = e
      console.log(e)
      this.privatePhoneApi(this.private_data)
    },
    query(){
      this.private_data.userId == '' ? this.private_data.userId = null : ''
      this.privatePhoneApi(this.private_data)
    },
    //重置
    resetButtonValue(){
      this.private_data.userId = null;
      this.private_data.pageNum = 1;
      this.private_data.limit = 10;
      // this.private_data.state = 1;
      this.private_data.state = this.options[0].state;
      this.privatePhoneApi(this.private_data)
    },
    // 处理电话
    changeGoodUserSign(id,sign){
      console.log(id,sign);
      this.processCallsApi({
        id:id,
        sign:sign
      })
    },
    //上传微信图片
    addWeChatBtn(){
      this.privateCustomerApi({field1:this.field1});
    },
  },
  beforeMount() {
     this.private_data.state = this.options[0].state
      this.privatePhoneApi(this.private_data)

  },
  beforeCreate() {
    if (window.localStorage.getItem('findboyAdmin') == null){
      console.log('是这么', window.localStorage.getItem('findboyAdmin'))
      // localStorage.removeItem('findBoy');
      // this.$router.push('/Login')
    }

  }

}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #c0ccda;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
