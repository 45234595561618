<template>
  <el-card>
    <el-button type="primary" @click="showIncomeDay">每日收益</el-button>
    <el-button type="primary" @click="showIncomeMonth">每月收益</el-button>
    <br><br>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="time" label="时间" width="200"/>
      <el-table-column prop="sumVip1" label="月会员订单收入" width="120"/>
      <el-table-column prop="numberVip1" label="月会员订单数量" width="120"/>
      <el-table-column prop="sumVip2" label="年会员订单收入" width="120"/>
      <el-table-column prop="numberVip2" label="年会员订单数量" width="120"/>
      <el-table-column prop="sumAll" label="订单收入总和" width="120"/>
      <el-table-column prop="number" label="订单数量总和" width="120"/>
    </el-table>
    <br>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="order.pageNum"
        :page-sizes="[10, 50, 100, 500]"
        :page-size="order.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </el-card>
</template>

<script>
import {showIncomeDay, showIncomeMonth} from "@/https";
import {formatDate, formatMonth} from "@/libs/tools";

export default {
  name: "OrderIncome",
  data() {
    return {
      order: {
        time: "",
        sumAll: "",
        sumVip1: "",
        sumVip2: "",
        number: "",
        numberVip1: "",
        numberVip2: "",
        pageNum: 1,
        limit: 10
      },
      tableData: [],
      total: 0
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.order.limit = val
      this.showIncomeDay()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.order.pageNum = val
      this.showIncomeDay()
    },
    refresh() {
      this.tableData = []
      this.showIncomeDay()
    },
    showIncomeDay() {
      this.tableData = []
      return new Promise(() => {
        showIncomeDay({
          pageNum: this.order.pageNum,
          limit: this.order.limit
        }).then(res => {
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.list.length; i++) {
            this.tableData.push({
              time: formatDate(data.list[i].time * 1000),
              sumAll: data.list[i].sumAll,
              sumVip1: data.list[i].sumVip1,
              sumVip2: data.list[i].sumVip2,
              number: data.list[i].number,
              numberVip1: data.list[i].numberVip1,
              numberVip2: data.list[i].numberVip2
            })
          }
        })
      })
    },
    showIncomeMonth() {
      this.tableData = []
      this.order.pageNum = 1
      return new Promise(() => {
        showIncomeMonth({
          pageNum: this.order.pageNum,
          limit: this.order.limit
        }).then(res => {
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.list.length; i++) {
            this.tableData.push({
              time: formatMonth(data.list[i].time * 1000),
              sumAll: data.list[i].sumAll,
              sumVip1: data.list[i].sumVip1,
              sumVip2: data.list[i].sumVip2,
              number: data.list[i].number,
              numberVip1: data.list[i].numberVip1,
              numberVip2: data.list[i].numberVip2
            })
          }
        })
      })
    }
  },
  mounted: function () {
    this.refresh();
  }
}
</script>

<style scoped>

</style>