<template>
  <div>
    <el-card>
      <el-row :gutter="24">
        <el-col :span="1.5">
          <div>邀请用户Id：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="recommend.userId"></el-input>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>被邀请用户Id：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="recommend.forId"></el-input>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>被邀请用户openId：</div>
        </el-col>
        <el-col :span="6">
          <el-input v-model="recommend.forOpenId"></el-input>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" @click="recommend.pageNum=1,showRecommend()">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="id" label="分享编号" width="100"/>
        <el-table-column prop="userId" label="推荐用户Id" width="120"/>
        <el-table-column prop="forId" label="被推荐用户Id" width="120"/>
        <el-table-column prop="forOpenId" label="被推荐用户openId"/>
        <el-table-column prop="createAt" label="推荐时间"/>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="recommend.pageNum"
          :page-sizes="[10, 50, 100, 500]"
          :page-size="recommend.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import {formatDateTime} from "@/libs/tools";
import {showRecommend} from "@/https";

export default {
  name: "Recommend",
  data() {
    return {
      recommend: {
        userId: "",
        forId: "",
        forOpenId: "",
        pageNum: 1,
        limit: 10
      },
      tableData: [],
      total: 0
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.recommend.limit = val
      this.showRecommend()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.recommend.pageNum = val
      this.showRecommend()
    },
    resetButtonValue() {
      this.recommend.userId = ''
      this.recommend.forId = ''
      this.recommend.forOpenId = ''
      this.recommend.pageNum = 1
      this.recommend.limit = 10
      this.showRecommend()
    },
    showRecommend() {
      this.tableData = []
      this.spinShow = true
      return new Promise(() => {
        showRecommend({
          userId: this.recommend.userId,
          forId: this.recommend.forId,
          forOpenId: this.recommend.forOpenId,
          pageNum: this.recommend.pageNum,
          limit: this.recommend.limit
        }).then(res => {

          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.list.length; i++) {
            this.tableData.push({
              id: data.list[i].id,
              userId: data.list[i].userId,
              forId: data.list[i].forId,
              forOpenId: data.list[i].forOpenId,
              createAt: formatDateTime(data.list[i].createAt * 1000)
            })
          }
        })
      })
    }
  },
  mounted: function () {
    this.showRecommend();
  }
}
</script>

<style scoped>

</style>