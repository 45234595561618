<template>
  <div>
    <el-card>
      <el-row :gutter="24">
        <el-col :span="1.5">
          <div>用户Id：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="recommendNum.userId"></el-input>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>邀请人数：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="recommendNum.number"></el-input>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" @click="recommendNum.pageNum=1,showRecommendNum()">查询
        </el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="userId" label="用户Id" width="100"/>
        <el-table-column prop="number" label="邀请数量" width="100"/>
        <el-table-column prop="prize7" label="七天会员领取状态"/>
        <el-table-column prop="prize15" label="十五天会员领取状态"/>
        <el-table-column prop="prize30" label="三十天会员领取状态"/>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                type="primary">编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="recommendNum.pageNum"
          :page-sizes="[10, 50, 100, 500]"
          :page-size="recommendNum.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
    <el-dialog title="修改会员领取状态" :visible.sync="dialogFormVisible" center style="width: 1500px" :modal-append-to-body = "false">
      <el-form :model="recommendNumModel">
        <el-row :span="15">
          <el-col :span="7">
            <el-form-item label="用户Id" :label-width="formLabelWidth">
              <el-input v-model="recommendNumModel.userId" style="width: 120px" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="用户邀请数量" :label-width="formLabelWidth">
              <el-input v-model="recommendNumModel.number" style="width: 120px" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="7天会员" :label-width="formLabelWidth">
              <el-select v-model="recommendNumModel.prize7" placeholder="7天会员" style="width: 120px">
                <el-option label="未领取" value="0"></el-option>
                <el-option label="已领取" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="1">
            <el-form-item label="15天会员" :label-width="formLabelWidth">
              <el-select v-model="recommendNumModel.prize15" placeholder="15天会员" style="width: 120px">
                <el-option label="未领取" value="0"></el-option>
                <el-option label="已领取" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="1">
            <el-form-item label="30天会员" :label-width="formLabelWidth">
              <el-select v-model="recommendNumModel.prize30" placeholder="30天会员" style="width: 120px">
                <el-option label="未领取" value="0"></el-option>
                <el-option label="已领取" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeGetVipStatus">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {changeGetVipStatus, showRecommendNum, showRecommendNumById} from "@/https";

export default {
  name: "RecommendNum",
  data() {
    return {
      recommendNum: {
        userId: "",
        number: "",
        pageNum: 1,
        limit: 10
      },
      recommendNumModel: {
        userId: "",
        number: "",
        prize7: "",
        prize15: "",
        prize30: ""
      },
      dialogTableVisible: false,
      dialogFormVisible: false,
      formLabelWidth: '120px',
      tableData: [],
      total: 0
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.recommendNum.limit = val
      this.showRecommendNum()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.recommendNum.pageNum = val
      this.showRecommendNum()
    },
    resetButtonValue() {
      this.recommendNum.userId = ''
      this.recommendNum.number = ''
      this.recommendNum.prize7 = ''
      this.recommendNum.prize15 = 1
      this.recommendNum.prize30 = 10
      this.showRecommendNum()
    },
    showRecommendNum() {
      this.tableData = []
      this.spinShow = true
      return new Promise(() => {
        showRecommendNum({
          userId: this.recommendNum.userId,
          number: this.recommendNum.number,
          pageNum: this.recommendNum.pageNum,
          limit: this.recommendNum.limit
        }).then(res => {

          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.list.length; i++) {
            this.tableData.push({
              userId: data.list[i].userId,
              number: data.list[i].number,
              prize7: data.list[i].prize7 === 0 ? '未领取' : '已领取',
              prize15: data.list[i].prize15 === 0 ? '未领取' : '已领取',
              prize30: data.list[i].prize30 === 0 ? '未领取' : '已领取'
            })
          }
        })
      })
    },
    handleEdit(index, row) {
      return new Promise(() => {
        showRecommendNumById({
          userId: row.userId
        }).then(res => {
          let data = res.data.data
          this.dialogFormVisible = true
          this.recommendNumModel.userId = data.userId
          this.recommendNumModel.number = data.number
          this.recommendNumModel.prize7 = data.prize7 === 0 ? '未领取' : '已领取'
          this.recommendNumModel.prize15 = data.prize15 === 0 ? '未领取' : '已领取'
          this.recommendNumModel.prize30 = data.prize30 === 0 ? '未领取' : '已领取'
        })
      })
    },
    changeGetVipStatus() {
      return new Promise(() => {
        changeGetVipStatus({
          userId: this.recommendNumModel.userId,
          prize7: this.recommendNumModel.prize7 === '未领取' ? 0 : this.recommendNumModel.prize7 === '已领取' ? 2 : this.recommendNumModel.prize7,
          prize15: this.recommendNumModel.prize15 === '未领取' ? 0 : this.recommendNumModel.prize15 === '已领取' ? 2 : this.recommendNumModel.prize15,
          prize30: this.recommendNumModel.prize30 === '未领取' ? 0 : this.recommendNumModel.prize30 === '已领取' ? 2 : this.recommendNumModel.prize30,
        }).then(res => {
          if (res.data.code === 200) {
            this.dialogFormVisible = false
            this.showRecommendNum()
          }else {
            this.open(res.data.msg)
          }
        })
      })
    },
    open(msg) {
      this.$message.error(msg)
    }
  },
  mounted: function () {
    this.showRecommendNum();
  }
}
</script>

<style scoped>

</style>