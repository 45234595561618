<template>
  <div>
    <el-card>
      <el-row :gutter="24" type="flex" align="middle">
        <el-col :span="1.5">
          <div>标题:</div>
        </el-col>
        <el-col :span="3">
          <el-input v-model="title" size="small"></el-input>
        </el-col>
        <el-date-picker
            size="small"
            v-model="value_time"
            value-format="timestamp"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
        <el-button style="margin-left: 20px" type="primary" @click="query">查询</el-button>
        <el-button style="margin-left: 20px" type="primary" @click="upload_channel = true">添加渠道</el-button>
        <el-button style="margin-left: 20px" @click="reset" >重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table border  :data="list_data">
        <el-table-column prop="id" label="编号" align="center" />
        <el-table-column prop="title" label="标题"  align="center" />
        <el-table-column label="二维码"  align="center">
          <template slot-scope="scope">
            <el-image
                style="width: 100px;height: 100px"
                :src="scope.row.code_url"
                ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="channel" label="渠道"   align="center" />
        <el-table-column prop="yesterdayNum" label="昨日数据"   align="center" />
        <el-table-column prop="toDayNum" label="今日数据"  align="center"  />
        <el-table-column prop="timeScopeNum" label="时间段数据"   align="center" />
        <el-table-column label="添加时间" align="center" >
          <template slot-scope="scope">
            <div>
              {{myDayJs(scope.row.created_at * 1000).format("YYYY-MM-DD HH:mm:ss") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作栏"  align="center">
          <template slot-scope="scope">
            <a :href="scope.row.code_url" download class="eliminate-a">
              <el-button  type="text" size="small">
                下载
              </el-button>
            </a>
            <el-button   @click="detailedChannel(scope.row.id)" type="text" size="small">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="detailed_data.pageNum"
          :page-size="detailed_data.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="detailed_data.total_count">
      </el-pagination>
    </el-card>
    <!--  添加渠道  -->
    <el-dialog title="添加渠道" :visible.sync="upload_channel" center style="width: 1200px" :modal-append-to-body = "false" :before-close="handleClose">

      <el-row :gutter="24" type="flex" align="middle" justify="center">
        <el-col :span="1.5">
          <div>标题:</div>
        </el-col>
        <el-col :span="10">
          <el-input v-model="dialog_title" ></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="24" type="flex" align="middle" justify="center"  style="margin-top: 20px">
        <el-col :span="1.5">
          <div>渠道:</div>
        </el-col>
        <el-col :span="10">
          <el-input v-model="dialog_channel" ></el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="addChannel">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import {addChanne, channelList, deleteChanne} from "../https";
import dayjs from "dayjs";
export default {
  name: "channelList",
  data(){
    return{
      title:'',//标题
      pageNum:1,
      limit:10,
      list_data:[],
      detailed_data:{
        pageNum:0,
        limit:0,
        total_count:0,
      },//分页数据
      myDayJs: dayjs,
      upload_channel:false,//上传渠道弹出框
      delete_channel:false,//删除渠道弹出框
      dialog_title:'',
      dialog_channel:'',
      value_time:'',
    }
  },
  methods:{
    async channelListApi(data){
       await channelList(data).then(res => {
        this.list_data = res.data.data.list;
        this.detailed_data.total_count = res.data.data.totalCount;
        this.detailed_data.total_page = res.data.data.totalPage;
      })
    },
    handleSizeChange(val) {
      this.detailed_data.limit = val;
    },
    handleCurrentChange(val){
      this.detailed_data.pageNum = val
    },
    query(){
      let data = {
        title:this.title.length == 0 ? null :this.title,
        pageNum:this.pageNum,
        limit:this.limit,
        startTime:this.value_time.length == 0 ? null : dayjs(this.value_time[0]).format('YYYY/MM/DD HH:mm:ss'),
        endTime:this.value_time.length == 0 ? null : dayjs(this.value_time[1]).format('YYYY/MM/DD HH:mm:ss')
      };
      console.log(data)
      // return;
      // eslint-disable-next-line no-unreachable
      this.channelListApi(data)
    },
    reset(){
      this.title = '';
      this.value_time = ''
      this.pageNum = 1
      this.limit = 10,
      this.query()
    },
    handleClose(){
      this.dialog_title = '';
      this.dialog_channel = '';
      this.upload_channel= false;
      this.delete_channel = false;


    },
    addChannelApi(data){
      return new Promise(() => {
        addChanne(data).then(res => {
          console.log(res.data.code,'res')
          if ( res.data.code == 200 ){
            this.$message({
              message: '添加成功',
              type: 'success'
            });
            this.handleClose()
            this.initialQuery()
          }
        })
      })
    },
    deleteChanneApi(data){
      return new Promise(() => {
        deleteChanne(data).then(res => {
          if (res.data.code == 200){
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.initialQuery()
          }
          console.log(res.data.code,'res')
        })
      })
    },
    detailedChannel(e){
      console.log(e)
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteChanneApi({id	:e})
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
      //
    },
    addChannel(){
      let data = {
        title:this.dialog_title,
        channel:this.dialog_channel
      }
      this.addChannelApi(data)
    },
    //初始查询
    initialQuery(){
      let dat = {
        pageNum:this.pageNum,
        limit:this.limit
      }
      this.channelListApi(dat)
    }

  },
  beforeMount() {
    this.initialQuery()
  },

}
</script>

<style scoped >
.channel-wrapper{
  width: 100%;
  height: 40px;
  background-color: red;
  display: flex;
  align-content: center;
  justify-content: center;
}
.eliminate-a{
  text-decoration: none;
  color: #fff;
  margin-right: 10px;
}
</style>
