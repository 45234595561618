<template>
  <div>
    <el-card>
      <el-row :gutter="24">
        <el-col :span="1.5">
          <div>评论Id：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="comment.commentId"></el-input>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>用户Id：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="comment.fromId"></el-input>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>帖子Id：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="comment.postId"></el-input>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>评论对象：</div>
        </el-col>
        <el-col :span="2">
          <el-select v-model="comment.toType" placeholder="请选择">
            <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>评论状态：</div>
        </el-col>
        <el-col :span="2">
          <el-select v-model="comment.status" placeholder="请选择">
            <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" @click="comment.pageNum=1,showComment()">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="commentId" label="评论Id" width="80"/>
        <el-table-column prop="fromId" label="用户Id" width="80"/>
        <el-table-column prop="postId" label="所在帖子Id" width="100"/>
        <el-table-column prop="toId" label="评论对象Id" width="100"/>
        <el-table-column prop="toType" label="评论对象类型" width="120"/>
        <el-table-column prop="words" label="文字内容"/>
        <el-table-column prop="favNum" label="点赞数" width="70"/>
        <el-table-column prop="commentNum" label="评论数" width="70"/>
        <el-table-column prop="status" label="评论状态" width="80">
          <template slot-scope="scope">
            <el-button
                size="mini"
                @click="changeCommentStatus(scope.$index, scope.row)"
                v-if="scope.row.status==='正常'"
                type="primary"
                v-text="scope.row.status">
            </el-button>
            <el-button
                size="mini"
                @click="changeCommentStatus(scope.$index, scope.row)"
                v-if="scope.row.status==='删除'"
                type="danger"
                v-text="scope.row.status">
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="createAt" label="创建时间" width="160"/>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="comment.pageNum"
          :page-sizes="[10, 50, 100, 500]"
          :page-size="comment.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import {changeCommentStatus, showComment} from "@/https";
import {formatDateTime} from "@/libs/tools";

export default {
  name: "Comment",
  data() {
    return {
      comment: {
        commentId: "",
        fromId: "",
        postId: "",
        toType: "",
        status: "",
        pageNum: 1,
        limit: 10
      },
      typeOptions: [{
        value: '0',
        label: '帖子'
      }, {
        value: '1',
        label: '评论'
      }],
      statusOptions: [{
        value: '0',
        label: '正常'
      }, {
        value: '1',
        label: '删除'
      }],
      dialogTableVisible: false,
      dialogFormVisible: false,
      tableData: [],
      total: 0
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.comment.limit = val
      this.showComment()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.comment.pageNum = val
      this.showComment()
    },
    resetButtonValue() {
      this.comment.commentId = ''
      this.comment.fromId = ''
      this.comment.postId = ''
      this.comment.toType = ''
      this.comment.status = ''
      this.comment.pageNum = 1
      this.comment.limit = 10
      this.showComment()
    },
    showComment() {
      this.tableData = []
      this.spinShow = true
      return new Promise(() => {
        showComment({
          commentId: this.comment.commentId,
          fromId: this.comment.fromId,
          postId: this.comment.postId,
          toType: this.comment.toType,
          status: this.comment.status,
          pageNum: this.comment.pageNum,
          limit: this.comment.limit
        }).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.list.length; i++) {
            this.tableData.push({
              commentId: data.list[i].commentId,
              fromId: data.list[i].fromId,
              postId: data.list[i].postId,
              toId: data.list[i].toId,
              toType: data.list[i].toType === 0 ? '帖子' : '评论',
              words: data.list[i].words,
              favNum: data.list[i].favNum,
              commentNum: data.list[i].commentNum,
              status: data.list[i].status === 0 ? '正常' : '删除',
              createAt: formatDateTime(data.list[i].createAt * 1000),
            })
          }
        })
      })
    },
    changeCommentStatus(index, row) {
      return new Promise(() => {
        changeCommentStatus({
          commentId: row.commentId
        }).then(res => {
          if (res.data.code === 200) {
            row.status = (row.status === '正常' ? '删除' : '正常')
          }else {
            this.open(res.data.msg)
          }
        })
      })
    },
    open(msg) {
      this.$message.error(msg)
    }
  },
  mounted: function () {
    this.showComment();
  }
}
</script>

<style scoped>

</style>