<template>
  <div>
    <el-card>
      <el-row :gutter="22">
        <el-col :span="1.5">
          <div>统计类型：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="detailed_data.count_type" placeholder="请选择">
            <el-option
                v-for="item in statistics"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="1.5">
          <div>会员类型：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="detailed_data.vip_type" placeholder="请选择">
            <el-option
                v-for="item in member"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="1.5">
          <div>时间类型：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="detailed_data.time_type" placeholder="请选择">
            <el-option
                v-for="item in time"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>


        <el-button style="margin-left: 20px" type="primary" @click="query">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table border style="width: 100%" :data="list_data">
        <el-table-column prop="id" label="ID" width="80"/>
        <el-table-column label="统计">
          <template slot-scope="scope">
            <div>
              {{ scope.row.count_type == "all" ? "总计" : scope.row.count_type == "vip" ? "会员" : scope.row.count_type == "tong" ? "同城卡" : "曝光卡" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="时间">
          <template slot-scope="scope">
            <div>
              {{ scope.row.time_type == "month" ? "月统计" : "日统计" }}
            </div>
          </template>

        </el-table-column>
        <el-table-column prop="price" label="金额"/>
        <el-table-column label="统计时间">
          <template slot-scope="scope">
            <div>
              {{ scope.row.day ? scope.row.year + "-" + scope.row.month + "-" + scope.row.day : scope.row.year + "-" + scope.row.month }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="detailed_data.pageNum"
          :page-size="detailed_data.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total_count">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import {incomeStatistics} from "../https";
import dayjs from 'dayjs'

export default {
  name: "nIncomeStatistics",
  data() {
    return {
      statistics:[{
        value: 'all',
        label: '所有'
      }, {
        value: 'vip',
        label: '会员'
      }, {
        value: 'bao',
        label: '曝光卡'
      },{
        value: 'tong',
        label: '同城卡'
      }],
      statistics_type:'',
      member:[{
        value: '0',
        label: '非会员用户'
      }, {
        value: '1',
        label: '普通会员用户'
      }, {
        value: '2',
        label: '黑钻会员用户'
      }],
      member_type:'',
      type_condition: [{
        value: 'vip',
        label: '会员'
      }, {
        value: 'tong',
        label: '同城卡'
      }, {
        value: 'bao',
        label: '曝光卡'
      }],
      detailed_data: {
        count_type: '',//统计类型
        vip_type: '',//会员类型
        time_type: '',//时间类型
        pageNum: 1,
        limit: 10,//每页数量
      },
      list_data: [],//所有数据
      total_count: 0,
      total_page: 0,
      myDayJs: dayjs,
      time:[{
        value: 'month',
        label: '月统计'
      }, {
        value: 'day',
        label: '每日统计'
      }],
    }
  },
  methods: {
    //收益统计Api
    memberListApi(data) {
      return new Promise(() => {
        incomeStatistics(data).then(res => {
          this.list_data = res.data.data.list;
          this.total_count = res.data.data.totalCount;
          this.total_page = res.data.data.totalPage;
        })
      })
    },
    //detailed_data.pageNum 改变时会触发
    handleSizeChange(val) {
      this.detailed_data.limit = val;
      this.memberListApi(this.detailed_data)
    },
    //detailed_data.limit 改变时会触发
    handleCurrentChange(val) {
      this.detailed_data.pageNum = val
      this.memberListApi(this.detailed_data)
    },
    //重置
    resetButtonValue(){
      this.detailed_data.count_type = ''
      this.detailed_data.vip_type = ''
      this.detailed_data.time_type = ''
      this.detailed_data.pageNum = 1
      this.detailed_data.limit = 10
      this.memberListApi({pageNum:1,limit:10})
    },
    //查询
    query() {
      let data = {
        count_type: this.detailed_data.count_type.length != 0 ? this.detailed_data.count_type : null,
        vip_type: this.detailed_data.vip_type.length != 0 ? this.detailed_data.vip_type : null,
        time_type: this.detailed_data.time_type.length != 0 ? this.detailed_data.time_type : null,
        pageNum: this.detailed_data.pageNum,
        limit: this.detailed_data.limit,//每页数量
      }
      this.memberListApi(data)
    },
  },
  beforeMount() {
    this.query()
  }
}
</script>

<style scoped>

</style>