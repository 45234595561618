<template>
  <div>
    <el-card>
      <el-row :gutter="24">
        <el-col :span="1.5">
          <div>用户Id：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="logoff.userId"></el-input>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>用户openId：</div>
        </el-col>
        <el-col :span="6">
          <el-input v-model="logoff.openId"></el-input>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>注销原因：</div>
        </el-col>
        <el-col :span="4">
          <el-select v-model="logoff.reason" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" @click="logoff.pageNum=1,showLogoff()">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="userId" label="用户Id" width="100"/>
        <el-table-column prop="openId" label="用户openId" width="250"/>
        <el-table-column prop="reason" label="注销原因" width="250"/>
        <el-table-column prop="description" label="描述" width="400"/>
        <el-table-column prop="logoffAt" label="注销时间"/>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="logoff.pageNum"
          :page-sizes="[10, 50, 100, 500]"
          :page-size="logoff.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import {showLogoff} from "@/https";
import {formatDateTime} from "@/libs/tools";

export default {
  name: "Logoff",
  data() {
    return {
      logoff: {
        userId: "",
        openId: "",
        reason: "",
        pageNum: 1,
        limit: 10
      },
      options: [{
        value: '0',
        label: '我已脱单'
      }, {
        value: '1',
        label: '我没有得到任何配对'
      }, {
        value: '2',
        label: '我不想被其他人看到'
      }, {
        value: '3',
        label: '我感觉找不到对象了'
      }, {
        value: '4',
        label: '我在FindBoy体验不佳'
      }, {
        value: '5',
        label: '其他原因'
      }],
      tableData: [],
      total: 0
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.logoff.limit = val
      this.showLogoff()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.logoff.pageNum = val
      this.showLogoff()
    },
    resetButtonValue() {
      this.logoff.userId = ''
      this.logoff.openId = ''
      this.logoff.reason = ''
      this.logoff.pageNum = 1
      this.logoff.limit = 10
      this.showLogoff()
    },
    showLogoff() {
      this.tableData = []
      this.spinShow = true
      return new Promise(() => {
        showLogoff({
          userId: this.logoff.userId,
          openId: this.logoff.openId,
          reason: this.logoff.reason,
          pageNum: this.logoff.pageNum,
          limit: this.logoff.limit
        }).then(res => {

          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.list.length; i++) {
            this.tableData.push({
              userId: data.list[i].userId,
              openId: data.list[i].openId,
              reason: data.list[i].reason === 0 ? '我已脱单' : data.list[i].reason === 1 ? '我没有得到任何配对' : data.list[i].reason === 2 ? '我不想被其他人看到' : data.list[i].reason === 3 ? '感觉找不到对象了' : data.list[i].reason === 4 ? '我在FindBoy体验不佳' : '其他原因',
              description: data.list[i].description,
              logoffAt: formatDateTime(data.list[i].logoffAt * 1000)
            })
          }
        })
      })
    }
  },
  mounted: function () {
    this.showLogoff();
  }
}
</script>

<style scoped>

</style>