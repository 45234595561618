import axios from "axios";
import Cookies from 'js-cookie'

// axios.defaults.baseURL = "http://192.168.0.186:8083/"
// axios.defaults.baseURL = "http://fbadmin.laobitang.com/"
axios.defaults.baseURL = "https://fbadmin.queqiaoxiangqin.com//"


// axios.defaults.baseURL = "http://50ai237715.qicp.vip/"

// 添加响应拦截器12
axios.interceptors.response.use(
    function (response) {
        // 对响应数据做点什么
        const {code, msg} = response.data;
        if (code === 200 || code === 500 || code === 400 || code == '200') {
            return response;
        }
        return Promise.reject(msg);
    },
    function () {
        // 对响应错误做点什么
        return Promise.reject("服务器错误");
    }
);

export const TOKEN_KEY = 'token'
export const USER_ID = 'userId'
export const USER_NAME = 'userName'

export const setToken = (token) => {
    let millisecond = new Date().getTime()
    let expiresTime = new Date(millisecond + 60 * 1000 * 240)
    Cookies.set(TOKEN_KEY, token, {expires: expiresTime})
}

export const getToken = () => {
    const token = Cookies.get(TOKEN_KEY)
    if (token) return token
    else return false
}

export const setUserId = (userId) => {
    Cookies.set(USER_ID, userId)
}
//清除token
export function removeToken() {
    return Cookies.remove(TOKEN_KEY)
}

export const setUserName = (userName) => {
    Cookies.set(USER_NAME, userName)
}

export const login = ({n, p}) => {
    const data = {
        n,
        p
    }
    return axios.request({
        url: '/v2/userAdmin/login',
        data: {
            username: data.n,
            password: data.p
        },
        method: 'post'
    })
}

export const dayRegisterNum = () => {
    return axios.request({
        url: '/v2/user/dayRegisterNum',
        headers: {'token': getToken()},
        method: 'get'
    })
}

export const weekRegisterNum = () => {
    return axios.request({
        url: '/v2/user/weekRegisterNum',
        headers: {'token': getToken()},
        method: 'get'
    })
}

export const monthRegisterNum = () => {
    return axios.request({
        url: '/v2/user/monthRegisterNum',
        headers: {'token': getToken()},
        method: 'get'
    })
}

export const showUserRegister = (params) => {
    return axios.request({
        url: '/v2/user/showUserRegister',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const showUserList = (params) => {
    return axios.request({
        url: '/v2/user/showUserList',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const showUserById = (params) => {
    return axios.request({
        url: '/v2/user/showUserById',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const changeUserCount = (data) => {
    return axios.request({
        url: '/v2/user/changeUserCount',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

export const showUserInfoList = (params) => {
    return axios.request({
        url: '/v2/userInfo/showUserInfoList',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const showUserInfoById = (params) => {
    return axios.request({
        url: '/v2/userInfo/showUserInfoById',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const changeUserInfo = (data) => {
    return axios.request({
        url: '/v2/userInfo/changeUserInfo',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

export const showVerify = (params) => {
    return axios.request({
        url: '/v2/userInfo/showVerify',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const changeVerify = (data) => {
    return axios.request({
        url: '/v2/userInfo/changeVerify',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

export const showUserVip = (params) => {
    return axios.request({
        url: '/v2/userVip/showUserVip',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const addUserVipTime = (data) => {
    return axios.request({
        url: '/v2/userVip/addUserVipTime',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

export const showLike = (params) => {
    return axios.request({
        url: '/v2/like/showLike',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const showWatchNum = (params) => {
    return axios.request({
        url: '/v2/watchNum/showWatchNum',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const showOrder = (params) => {
    return axios.request({
        url: '/v2/order/showOrder',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const showOrderById = (params) => {
    return axios.request({
        url: '/v2/order/showOrderById',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const changeOrder = (data) => {
    return axios.request({
        url: '/v2/order/changeOrder',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

export const showIncomeDay = (params) => {
    return axios.request({
        url: '/v2/order/showIncomeDay',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const showIncomeMonth = (params) => {
    return axios.request({
        url: '/v2/order/showIncomeMonth',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const showRecommend = (params) => {
    return axios.request({
        url: '/v2/recommend/showRecommend',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const showRecommendNum = (params) => {
    return axios.request({
        url: '/v2/recommendNum/showRecommendNum',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const showRecommendNumById = (params) => {
    return axios.request({
        url: '/v2/recommendNum/showRecommendNumById',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const changeGetVipStatus = (data) => {
    return axios.request({
        url: '/v2/recommendNum/changeGetVipStatus',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

export const showLogoff = (params) => {
    return axios.request({
        url: '/v2/logoff/showLogoff',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const showPost = (params) => {
    return axios.request({
        url: '/v2/post/showPost',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const changePostStatus = (data) => {
    return axios.request({
        url: '/v2/post/changePostStatus',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

export const showPostVerify = (params) => {
    return axios.request({
        url: '/v2/post/showPostVerify',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const changePostVerify = (data) => {
    return axios.request({
        url: '/v2/post/changePostVerify',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

export const addPost = (data) => {
    return axios.request({
        url: '/v2/post/addPost',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

export const showComment = (params) => {
    return axios.request({
        url: '/v2/comment/showComment',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const changeCommentStatus = (data) => {
    return axios.request({
        url: '/v2/comment/changeCommentStatus',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

export const showWatchInfo = (params) => {
    return axios.request({
        url: '/v2/watchInfo/showWatchInfo',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const showBlack = (params) => {
    return axios.request({
        url: '/v2/blackPost/showBlack',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const changeBlack = (data) => {
    return axios.request({
        url: '/v2/blackPost/changeBlack',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

export const addBlack = (data) => {
    return axios.request({
        url: '/v2/blackPost/addBlack',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

export const showPerfectVip = (params) => {
    return axios.request({
        url: '/v2/perfectVip/showPerfectVip',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

//会员列表
export const memberList = (params) => {
    return axios.request({
        url: '/v2/newOrder/list',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}
//Income statistics 收益统计

    export const incomeStatistics = (params) => {
    return axios.request({
        url: '/v2/count/list',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

//新用户信息
export const userInfo = (params) => {
    return axios.request({
        url: '/v2/newUserInfo/list',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

//宝藏卡获取记录
export const treasureCard = (params) => {
    return axios.request({
        url: '/v2/getBaoRecord/list',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

//宝藏卡使用记录
export const useBaoRecordList = (params) => {
    return axios.request({
        url: '/v2/useBaoRecord/list',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

//开通会员

export const nOpenMember = (data) => {
    return axios.request({
        url: '/v2/adminRechargeVip/recharge',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

//获取新会员商品

export const newMemberProducts = (params) => {
    return axios.request({
        url: '/v2/vipList/list',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

//后台充值列表
export const resetRecord = (params) => {
    return axios.request({
        url: '/v2/adminRechargeVip/list',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

// 后台充值私人订制记录
export const resetRecordPrivate = (params) => {
    return axios.request({
        url: '/v2/customMadeOrder/list',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}


//后台宝藏卡充值记录
export const backstageCard = (params) => {
    return axios.request({
        url: '/v2/adminRechargeBao/list',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

//后台充值宝藏卡
export const backstageRecharge = (data) => {
    return axios.request({
        url: '/v2/adminRechargeBao/recharge',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

//后台上传微信号
export const backstageWeChat = (data) => {
    return axios.request({
        url: '/v2/control/update/kefu',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

//渠道列表
export const channelList = (params) => {
    return axios.request({
        url: '/v2/channel/listing',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

//添加渠道
export const addChanne = (data) => {
    return axios.request({
        url: '/v2/channel/adding',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}
//删除渠道
export const deleteChanne = (params) => {
    return axios.request({
        url: '/v2/channel/del',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}


// 优质用户推荐
export const premiumUsers = (data) => {
    return axios.request({
        url: '/v2/userInfo/changeGoodUserSign',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

// 私人订制电话
export const privateCustomizedPhone = (params) => {
    return axios.request({
        url: '/v2/customMadeOrder/list',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

// 私人订制电话处理
export const privatePhoneHandle = (data) => {
    return axios.request({
        url: '/v2/customMadeOrder/updateOrdeSign',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

//修改私人客服微信
export const privateCustomer = (data) => {
    return axios.request({
        url: '/v2/customMadeOrder/updatePrivateKefu',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

//私人订单统计
export const privateOrder = (params) => {
    return axios.request({
        url: '/v2/customMadeOrder/countList',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

//周末夜话轮播图上传
export const weekendRotationUpload = (data) => {
    return axios.request({
        url: '/v2/adminBannerUpload/upload',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

// 活动图片开关
export const activityImg = (data) => {
    return axios.request({
        url: '/v2/adminBannerUpload/updateActivity',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

//轮播图列表
export const swiperList = (data) => {
    return axios.request({
        url: '/v2/adminBannerUpload/actList',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}
//修改价格
export const getPrivice = (data) => {
    return axios.request({
        url: '/v2/adminBannerUpload/updateActivity',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

// 活动详情

export const activityDetails = (data) => {
    return axios.request({
        url: '/v2/adminBannerUpload/message/list',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

//匹配按钮
export const matching = (data) => {
    return axios.request({
        url: '/v2/adminBannerUpload/match',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

//添加活动报名时间
export const addActivityTime = (data) => {
    return axios.request({
        url: '/v2/adminBannerUpload/upload',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

// 添加海报
export const addPoster = (data) => {
    return axios.request({
        url: '/v3/actPopup/popupAdd',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}
//海报列表
export const postList = () => {
    return axios.request({
        url: '/v3/actPopup/list',
        headers: {'token': getToken()},
        method: 'get'
    })
}
// 推送
export const pushPoster = (id) => {
    return axios.request({
        url: '/v3/actPopup/push/'+id,
        headers: {'token': getToken()},
        method: 'get'
    })
}
