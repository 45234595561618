<template>
  <div>
    <el-card>
      <el-row :gutter="22">
        <el-col :span="1.5">
          <div>用户Id：</div>
        </el-col>
        <el-col :span="3">
          <el-input v-model="detailed_data.user_id"></el-input>
        </el-col>
        <el-col :span="1.5">
          <div>订单编号：</div>
        </el-col>
        <el-col :span="4">
          <el-input v-model="detailed_data.order_number"></el-input>
        </el-col>
        <el-col :span="1.5">
          <div>第三方订单号：</div>
        </el-col>
        <el-col :span="4">
          <el-input v-model="detailed_data.third_party_number"></el-input>
        </el-col>
        <el-col :span="1.5">
          <div>类型条件：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="detailed_data.condition_type" placeholder="请选择">
            <el-option
                v-for="item in type_condition"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <br><br><br>
        <el-col :span="1.5">
          <div>支付时间：</div>
        </el-col>
        <el-col :span="7">
          <el-date-picker
              v-model="detailed_data.payment_time"
              type="daterange"
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-col>

        <el-button style="margin-left: 20px" type="primary" @click="query">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table border style="width: 100%" :data="list_data">
        <el-table-column prop="id" label="订单编号" width="80"/>
        <el-table-column prop="user_id" label="用户Id" width="80"/>
        <el-table-column prop="out_trade_no" label="商家订单号"/>
        <el-table-column prop="trade_no" label="第三方订单号"/>
        <el-table-column prop="price" label="价格" width="80"/>
        <el-table-column label="类型" width="160">
          <template slot-scope="scope">
            <div>
              {{ scope.row.type === 'vip' ? '会员' : scope.row.type === 'tong' ? '同城卡' : '曝光卡' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="vip_id" label="会员商品" width="140"/>
        <el-table-column label="支付时间" width="160">
          <template slot-scope="scope">
            <div>
              {{ myDayJs(scope.row.pay_time * 1000).format("YYYY-MM-DD HH:mm:ss") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="订单创建时间">
          <template slot-scope="scope">
            <div>
              {{ myDayJs(scope.row.created_at * 1000).format("YYYY-MM-DD HH:mm:ss") }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="detailed_data.pageNum"
          :page-size="detailed_data.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total_count">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import {memberList} from "../https";
import dayjs from 'dayjs'

export default {
  name: "nOrderQuery",
  data() {
    return {
      type_condition: [{
        value: 'vip',
        label: '会员'
      }, {
        value: 'tong',
        label: '同城卡'
      }, {
        value: 'bao',
        label: '曝光卡'
      }],
      detailed_data: {
        user_id: '',
        order_number: '',//订单编号
        third_party_number: '',//第三方编号
        condition_type: '',//类型条件
        payment_time: [],//支付时间
        pageNum: 1,
        limit: 10,//每页数量
      },
      list_data: [],//所有数据
      total_count: 0,
      total_page: 0,
      myDayJs: dayjs,
    }
  },
  methods: {
    //会员列表Api
    memberListApi(data) {
      return new Promise(() => {
        memberList(data).then(res => {
          this.list_data = res.data.data.list;
          this.total_count = res.data.data.totalCount;
          this.total_page = res.data.data.totalPage;
          console.log(res.data, this.list_data)
        })
      })
    },
    //detailed_data.pageNum 改变时会触发
    handleSizeChange(val) {
      this.detailed_data.limit = val;
      this.query()
    },
    //重置

    resetButtonValue() {
      this.detailed_data.user_id = ''
      this.detailed_data.order_number = ''
      this.detailed_data.third_party_number = ''
      this.detailed_data.condition_type = ''
      this.detailed_data.payment_time = ''

      this.detailed_data.pageNum = 1
      this.detailed_data.limit = 10
      this.memberListApi({pageNum: 1, limit: 10})
    },
    //detailed_data.limit 改变时会触发
    handleCurrentChange(val) {
      this.detailed_data.pageNum = val
      this.query()
    },
    //查询
    query() {
      console.log(this.detailed_data.payment_time[0]);
      let data = {
        user_id: this.detailed_data.user_id.length != 0 ? this.detailed_data.user_id : null,
        out_trade_no: this.detailed_data.order_number.length != 0 ? this.detailed_data.order_number : null,
        trade_no: this.detailed_data.third_party_number.length != 0 ? this.detailed_data.third_party_number : null,
        type: this.detailed_data.condition_type.length != 0 ? this.detailed_data.condition_type : null,
        starTime: this.detailed_data.payment_time.length != 0 ? (this.detailed_data.payment_time[0] / 1000).toString() : null,
        entTime: this.detailed_data.payment_time.length != 0 ? (this.detailed_data.payment_time[1] / 1000 + (59 + (59 * 60) + (60 * 60 * 23))).toString() : null,
        pageNum: this.detailed_data.pageNum,
        limit: this.detailed_data.limit,//每页数量
      }
      this.memberListApi(data)
    },
  },
  beforeMount() {
    this.query()
  }
}
</script>

<style scoped>

</style>