import Vue from "vue";
import VueRouter from "vue-router";
import Main from "@/components/Main"
import Login from "@/pages/Login";
import UserAccount from "@/pages/UserAccount";
import UserRegister from "@/pages/UserRegister";
import Order from "@/pages/Order";
import {getToken} from "@/https";
import UserInfo from "@/pages/UserInfo";
import UserVip from "@/pages/UserVip";
import Recommend from "@/pages/Recommend";
import RecommendNum from "@/pages/RecommendNum";
import Like from "@/pages/Like";
import Logoff from "@/pages/Logoff";
import WatchNum from "@/pages/WatchNum";
import OrderIncome from "@/pages/OrderIncome";
import Post from "@/pages/Post";
import Comment from "@/pages/Comment";
import WatchInfo from "@/pages/WatchInfo";
import BlackPost from "@/pages/BlackPost";
import UserVerify from "@/pages/UserVerify";
import PostVerify from "@/pages/PostVerify";
import PerfectVip from "@/pages/PerfectVip";
import nOrderQuery from "../pages/nOrderQuery";
import nIncomeStatistics from "../pages/nIncomeStatistics";
import nUserInfo from "../pages/nUserInfo";
import nGetTreasureCard from "../pages/nGetTreasureCard";
import nUseTreasureCard from "../pages/nUseTreasureCard";
import nMemberProducts from "../pages/nMemberProducts";
import bgcResetRecord from "../pages/bgcResetRecord";
import nOrderRevenue from "../pages/nOrderRevenue";
import bgcResetRecordCard from "../pages/bgcResetRecordCard";
import channelStatistics from "../pages/channelStatistics";
import channelList from "../pages/channelList";
import privatePhone from "../pages/privatePhone";
import privateOrderReven from "../pages/privateOrderReven";
import weekendTalk from "../pages/weekendTalk";
import weekendDetails from "../pages/weekendDetails";
Vue.use(VueRouter);

const LOGIN_PAGE_NAME = 'login'
const routes = [
    {
        path: '/',
        redirect: {
            name: 'privatePhone',
            path: '/privatePhone',
        }
    },
    {
        path: '/login',
        name: 'login',//路由名称
        component: Login//组件对象
    },
    //用户信息
    {
        path: '/user',
        meta: {
            title: "用户信息"
        },
        component: Main,
        children: [
            {
                path: "/",
                name: 'user',
                component: UserRegister,
                meta: { title: '用户注册量', icon: 'table' }
            },
            {
                path: '/userRegister',
                name: 'userRegister',
                component: UserRegister,
                meta: { title: '用户注册量', icon: 'table' }
            },
            {
                path: '/userAccount',
                name: 'userAccount',
                component: UserAccount,
                meta: { title: '用户账户', icon: 'table' }
            },
            {
                path: '/userInfo',
                name: 'userInfo',
                component: UserInfo,
                meta: { title: '用户信息', icon: 'table' }
            },
            {
                path: '/userVip',
                name: 'userVip',
                component: UserVip
            },
            {
                path: '/userVerify',
                name: 'userVerify',
                component: UserVerify,
                meta: { title: '资料审核', icon: 'table' }
            }
        ]
    },
    //广场
    {
        path: '/post',
        component: Main,
        meta: { title: '广场'},
        children: [
            {
                path: '/post',
                name: 'post',
                meta: { title: '帖子'},
                component: Post
            },
            {
                path: '/comment',
                meta: { title: '评论'},
                name: 'comment',
                component: Comment
            },
            {
                path: '/watchInfo',
                name: 'watchInfo',
                meta: { title: '每日查看资料'},
                component: WatchInfo
            },
            {
                path: '/blackPost',
                name: 'blackPost',
                meta: { title: '封禁名单'},
                component: BlackPost
            },
            {
                path: '/postVerify',
                name: 'postVerify',
                meta: { title: '帖子审核'},
                component: PostVerify
            }
        ]
    },
    //订单
    {
        path: '/order',
        component: Main,
        meta: { title: '订单'},
        children: [
            {
                path: '/order',
                name: 'order',
                component: Order
            },
            {
                path: '/orderIncome',
                name: 'orderIncome',
                component: OrderIncome
            },
            {
                path: '/perfectVip',
                name: 'perfectVip',
                meta: { title: '免费领取会员'},
                component: PerfectVip
            }
        ]
    },
    //分享邀请
    {
        path: '/recommend',
        meta: {
            access: [],
            title: "分享邀请"
        },
        component: Main,
        children: [
            {
                path: '/recommend',
                name: 'recommend',
                meta: { title: '邀请查询'},
                component: Recommend
            },
            {
                path: '/recommendNum',
                name: 'recommendNum',
                meta: { title: '邀请数量'},
                component: RecommendNum
            }
        ]
    },
    //喜欢记录
    {
        path: '/like',
        component: Main,
        children: [
            {
                path: '/like',
                name: 'like',
                meta: { title: '喜欢记录'},
                component: Like
            }
        ]
    },
    //每日浏览次数
    {
        path: '/watchNum',
        component: Main,
        children: [
            {
                path: '/watchNum',
                name: 'watchNum',
                meta: { title: '每日浏览次数'},
                component: WatchNum
            }
        ]
    },
    {
        path: '/logoff',
        component: Main,
        redirect: '/logoff',
        children: [
            {
                path: '/logoff',
                name: 'logoff',
                component: Logoff,
                meta: {
                    title: "注销记录"
                },
            }
        ]
    },
    //渠道
    {
        path: '/channelStatistics',
        component: Main,
        meta: { title: '渠道'},
        children: [
            {
                path: '/channelStatistics',
                name: 'channelStatistics',
                component: channelStatistics
            },
            {
                path: '/channelList',
                name: 'channelList',
                meta: { title: '渠道列表'},
                component: channelList
            }
        ]
    },
    {
        path: '/privatePhone',
        component: Main,
        children: [
            {
                path: '/privatePhone',
                name: 'privatePhone',
                meta: { title: '私人订制手机号'},
                component: privatePhone
            }
        ]
    },

    //新版会员
    {
        path: '/nOrderQuery',
        component: Main,
        meta: { title: '新版会员'},
        children: [
            {
                path: '/nOrderQuery',
                name: 'nOrderQuery',
                meta: { title: '订单查询'},
                component: nOrderQuery
            },
            {
                path: '/nIncomeStatistics',
                name: 'nIncomeStatistics',
                component: nIncomeStatistics
            },
            {
                path: '/nUserInfo',
                name: 'nUserInfo',
                meta: { title: '用户信息'},
                component: nUserInfo
            },
            {
                path: '/nGetTreasureCard',
                name: 'nGetTreasureCard',
                meta: { title: '宝藏卡获取记录'},
                component: nGetTreasureCard
            },
            {
                path: '/nUseTreasureCard',
                name: 'nUseTreasureCard',
                meta: { title: '宝藏卡使用记录'},
                component: nUseTreasureCard
            },
            {
                path: '/nMemberProducts',
                name: 'nMemberProducts',
                meta: { title: '新会员商品'},
                component: nMemberProducts
            },
            {
                path: '/bgcResetRecord',
                name: 'bgcResetRecord',
                meta: { title: '后台充值会员'},
                component: bgcResetRecord
            },
            {
                path: '/nOrderRevenue',
                name: 'nOrderRevenue',
                meta: { title: '订单收益'},
                component: nOrderRevenue
            },
            {
                path: '/bgcResetRecordCard',
                name: 'bgcResetRecordCard',
                meta: { title: '后台充值宝藏卡'},
                component: bgcResetRecordCard
            },

            {
                path: '/privateOrderReven',
                name: 'privateOrderReven',
                component: privateOrderReven
            },

        ]
    },
    //周末夜话
        {
        path: '/weekendTalk',
        component: Main,
        meta: { title: '周末夜话'},
        children: [
            {
                path: '/weekendTalk',
                name: 'weekendTalk',
                component: weekendTalk
            },
            {
                path: '/weekendDetails',
                name: 'weekendDetails',
                meta: { title: '周末夜话详情'},
                component: weekendDetails
            }
        ]
    },

    // {
    //     path: '/weekendTalk',
    //     component: Main,
    //     // redirect: '/weekendTalk',
    //     meta: { title: '周末日夜话'},
    //     children: [
    //         {
    //             path: '/weekendTalk',
    //             name: 'weekendTalk',
    //             component: weekendTalk,
    //             meta: {
    //                 title: "周末夜话"
    //             },
    //         },
    //         {
    //             path: '/bgcResetRecordCard',
    //             name: 'bgcResetRecordCard',
    //             meta: { title: '后台充值宝藏卡'},
    //             component: bgcResetRecordCard
    //         },
    //     ]
    // },

];

const router = new VueRouter({
    routes,
});

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
    const token = getToken()
    if (!token && to.name !== LOGIN_PAGE_NAME) {
        // 未登录且要跳转的页面不是登录页
        next({
            name: LOGIN_PAGE_NAME // 跳转到登录页
        })
    } else if (!token && to.name === LOGIN_PAGE_NAME) {
        // 未登陆且要跳转的页面是登录页
        next() // 跳转
    }else {
        if (token === null || token === '') {
            next('login');
        } else {
            next();
        }
    }
});



export default router;
