<template>
  <div>
    <el-card>
      <el-row :gutter="24">
        <el-col :span="1.5">
          <div>帖子Id：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="post.postId"></el-input>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>审核状态：</div>
        </el-col>
        <el-col :span="4">
          <el-select v-model="post.status" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" @click="post.pageNum=1,showPostVerify()">查询
        </el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column label="审核是否通过" width="160">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="primary"
                @click="changePostVerify(scope.$index, scope.row,0)">通过
            </el-button>
            <el-button
                size="mini"
                type="danger"
                @click="changePostVerify(scope.$index, scope.row,3)">不通过
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="审核状态" width="100"/>
        <el-table-column prop="postId" label="帖子Id" width="80"/>
        <el-table-column prop="words" label="文字内容"/>
        <el-table-column prop="imgs" label="图片内容">
          <template slot-scope="scope">
            <span v-for="(item,index) in (scope.row.imgs||'').split(',')" :key="index">
              <el-popover placement="left" trigger="click" width="300">
                <img :src="item" width="100%"/>
                <img
                    slot="reference"
                    :src="item"
                    :alt="item"
                    style="max-height: 70px;max-width: 70px; padding: 5px"
                />
              </el-popover>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="nickName" label="发帖时间" width="100"/>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="post.pageNum"
          :page-sizes="[10, 50, 100, 500]"
          :page-size="post.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import {changePostVerify, showPostVerify} from "@/https";
import {formatDateTime} from "@/libs/tools";

export default {
  name: "PostVerify",
  data() {
    return {
      post: {
        postId: "",
        status: "",
        pageNum: 1,
        limit: 10
      },
      options: [{
        value: '0',
        label: '审核通过'
      }, {
        value: '1',
        label: '删除'
      }, {
        value: '2',
        label: '提交审核中'
      }, {
        value: '3',
        label: '审核未通过'
      }],
      dialogImageUrl: '',
      dialogVisible: false,
      tableData: [],
      total: 0,
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.post.limit = val
      this.showPostVerify()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.post.pageNum = val
      this.showPostVerify()
    },
    resetButtonValue() {
      this.post.postId = ''
      this.post.status = ''
      this.post.pageNum = 1
      this.post.limit = 10
      this.showPostVerify()
    },
    showPostVerify() {
      this.tableData = []
      this.spinShow = true
      return new Promise(() => {
        showPostVerify({
          postId: this.post.postId,
          status: this.post.status,
          pageNum: this.post.pageNum,
          limit: this.post.limit
        }).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.list.length; i++) {
            this.tableData.push({
              postId: data.list[i].postId,
              userId: data.list[i].userId,
              words: data.list[i].words,
              age: data.list[i].age,
              imgs: data.list[i].imgs,
              status: data.list[i].status === 0 ? '审核通过' : data.list[i].perfect === 1 ? '删除' : data.list[i].perfect === 2 ? '审核提交中' : '审核未通过',
              createAt: formatDateTime(data.list[i].createAt * 1000)
            })
          }
        })
      })
    },
    changePostVerify(index, row, verifyStatus) {
      return new Promise(() => {
        changePostVerify({
          postId: row.postId,
          status: verifyStatus,
        }).then(res => {
          if (res.data.code === 200) {
            this.showPostVerify()
          }else {
            this.open(res.data.msg)
          }
        })
      })
    },
    open(msg) {
      this.$message.error(msg)
    }
  },
  mounted: function () {
    this.showPostVerify();
  }
}
</script>

<style scoped>

</style>